import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSDK } from '../../../../utils/scanVIN/sdk'
import { useStore } from '../../../../context/scanStore'

const ScannerComponent = () => {
  const host = useRef(null)
  const { loaded, sdk } = useSDK()
  const { setBarcode, keepCameraOn } = useStore()
  const navigate = useNavigate()

  const shouldKeepCameraOn = useCallback(async () => {
    if (!keepCameraOn) {
      await sdk.enableCamera(false)
    }
  }, [sdk, keepCameraOn])

  const onScan = useMemo(
    () => ({
      didScan: async (_, session) => {
        if (session.newlyRecognizedBarcodes.length > 0) {
          await sdk.enableScanning(false)
          await shouldKeepCameraOn()
          setBarcode(session.newlyRecognizedBarcodes[0])
          navigate('/vin/decoder')
        }
      },
    }),
    [setBarcode, sdk, navigate, shouldKeepCameraOn],
  )

  const enterFullScreen = async () => {
    if (host.current.requestFullscreen) {
      await host.current.requestFullscreen()
    } else if (host.current.webkitRequestFullscreen) {
      // Safari
      await host.current.webkitRequestFullscreen()
    } else if (host.current.msRequestFullscreen) {
      // IE
      await host.current.msRequestFullscreen()
    }
  }

  useEffect(() => {
    async function onMount() {
      if (loaded && host.current) {
        // Lock the screen orientation only if supported
        if (screen.orientation && screen.orientation.lock) {
          try {
            await enterFullScreen()
            await screen.orientation.lock('portrait')
          } catch (error) {
            console.warn('Screen orientation lock not supported:', error)
          }
        }

        sdk.connectToElement(host.current)
        await sdk.enableCamera(true)
        await sdk.enableScanning(true)
        sdk.addBarcodeCaptureListener(onScan)
      }
    }

    if (loaded) {
      void onMount()
    }

    return () => {
      if (loaded) {
        sdk.removeBarcodeCaptureListener(onScan)
        sdk.detachFromElement()

        // Unlock the screen orientation only if supported
        if (screen.orientation && screen.orientation.unlock) {
          screen.orientation.unlock()
        }
      }
    }
  }, [loaded, sdk, onScan])

  document.addEventListener('fullscreenchange', () => {
    if (!document.fullscreenElement) {
      navigate('/vin/decoder')
    }
  })

  return (
    <div>
      <div ref={host} className='w-full h-full' />
    </div>
  )
}

export default ScannerComponent
