import React, { Fragment, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import momenttz from 'moment-timezone'
import { useNavigate, useParams } from 'react-router-dom'

import CustomToast from '../../../../components/Custom/CustomToast'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomToolTip from '../../../../components/Custom/CustomToolTip'
import CustomPageTitle from '../../../../components/Custom/CustomPageTitle'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import vehicleBrokenImage from '../../../../utils/brokenImage/vehicleBrokenImage'

import Placeholder_Image from '../../../../assets/car/car-dummy.png'

import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/flatpickr.min.css'
import CopyToClipboard from '../../../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../../../components/Icons'

const {
  FaRegCircleCheck,
  FaHeart,
  FaRegHeart,
  FaTrash,
  IoArrowUp,
  IoRemoveCircleOutline,
} = Icons

const statusList = [
  { value: 'live', label: 'Launch To Active' },
  { value: 'scheduled', label: 'Scheduled' },
]

const timeZoneList = [
  { value: 'Canada/Pacific', label: 'Pacific Time (UTC-7)' },
  { value: 'Canada/Mountain', label: 'Mountain Time (UTC-6)' },
  { value: 'Canada/Central', label: 'Central Time (UTC-5)	' },
  { value: 'Canada/Eastern', label: 'Eastern Time (UTC-4)' },
  { value: 'Canada/Atlantic', label: 'Atlantic Time (UTC-3)' },
  { value: 'Canada/Newfoundland', label: 'Newfoundland Time (UTC -2:30)' },
]

const Launch = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setvehicleDetails] = useState()

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/details/scheduled/${id}`,
      )
      if (response.data.success) {
        setvehicleDetails(response.data.vehicleDetails)
        if (response.data.vehicleDetails.vehicle_auction?.address) {
          setSelectedSellerAddress(
            response.data.vehicleDetails.vehicle_auction?.address,
          )
        }
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const [buyersList, setBuyersList] = useState([])
  const [isBuyersLoading, setIsBuyersLoading] = useState(false)
  const [preferredBuyersList, setPreferredBuyersList] = useState([])
  const [unPreferredBuyersList, setUnPreferredBuyersList] = useState([])

  const [searchQueryMain, setSearchQueryMain] = useState('')
  const [searchQuerySelected, setSearchQuerySelected] = useState('')

  const [sellerAddresses, setSellerAddresses] = useState([])
  const [selectedSellerAddress, setSelectedSellerAddress] = useState(null)

  const [filteredBuyersList, setFilteredBuyersList] = useState([])
  const [selectedBuyersList, setSetSelectedBuyersList] = useState([])

  const [invitedBuyers, setInvitedBuyers] = useState([])
  const [auctionDetails, setAuctionDetails] = useState(null)

  const [isAllBuyersSelected, setIsAllBuyersSelected] = useState(false) // State to track "All Buyers" checkbox
  const [isAllPreferredSelected, setIsAllPreferredSelected] = useState(false) // State to track "All Preferred" checkbox

  const fetchAuctionData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-auctions/details/${id}`,
      )
      if (response.data.success) {
        setAuctionDetails(response.data.vehicleAuction)
        if (
          response?.data?.vehicleAuction?.status === 'live' ||
          response?.data?.vehicleAuction?.status === 'scheduled'
        ) {
          setSelectedStatus(response?.data?.vehicleAuction?.status)
        } else {
          setSelectedStatus(null)
        }
        setInvitedBuyers([
          ...(response.data.vehicleAuction?.invited_buyers ?? []),
        ])

        if (response.data.vehicleAuction?.address) {
          setSelectedSellerAddress(response.data.vehicleAuction.address)
        }

        if (response?.data?.vehicleAuction?.launch_auction_date) {
          setDatetime(response?.data?.vehicleAuction?.launch_auction_date)
          setTimezone(
            timeZoneList.find(
              (timezone) =>
                timezone.value ===
                response?.data?.vehicleAuction?.launch_auction_timezone,
            ),
          )
          const formattedDate = moment(
            response?.data?.vehicleAuction?.launch_auction_date,
            'YYYY-MM-DD hh:mm A',
          )
          const formattedTime = moment(
            response?.data?.vehicleAuction?.launch_auction_date,
            'YYYY-MM-DD hh:mm A',
          )

          setDate(moment(formattedDate).format('YYYY-MM-DD'))
          setTime(moment(formattedTime).format('HH:mm'))
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }, [id])

  useEffect(() => {
    fetchAuctionData()
  }, [fetchAuctionData])

  const fetchSellerAddress = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/seller/vehicle-auctions/list/address',
      )
      if (response.data.success) {
        setSellerAddresses(response.data.dealerDetails?.address)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const fetchBuyersList = useCallback(async () => {
    setIsBuyersLoading(true)
    setBuyersList([])
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-auctions/list/province/users?province=${selectedSellerAddress.province}`,
      )
      if (response.data.success) {
        const usersList = response.data.usersList
        setBuyersList(
          usersList.map((buyer) => ({
            ...buyer,
            isSelected: invitedBuyers.includes(buyer?.user_id?._id),
          })),
        )

        setIsAllBuyersSelected(
          usersList.every((buyer) =>
            invitedBuyers.includes(buyer?.user_id?._id),
          ),
        )
      }
      setIsBuyersLoading(false)
    } catch (error) {
      setIsBuyersLoading(false)
      handleRequestError(error)
    }
  }, [selectedSellerAddress?.province, invitedBuyers])

  useEffect(() => {
    if (selectedSellerAddress?.province) {
      fetchBuyersList()
    }
  }, [fetchBuyersList, selectedSellerAddress?.province])

  const fetchPreferredBuyersList = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/seller/vehicle-auctions/list/preferred/users',
      )
      if (response.data.success) {
        setPreferredBuyersList(response.data.preferredUsersList)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const handleAddressChange = (address) => {
    setSelectedSellerAddress(address)
    setInvitedBuyers([])
    handleRemoveAllSelected()
  }

  useEffect(() => {
    fetchSellerAddress()
    fetchPreferredBuyersList()
  }, [])

  useEffect(() => {
    const data = buyersList
      .filter((user) => !preferredBuyersList?.includes(user?.user_id?._id))
      .map((user) => user?.user_id?._id)

    setUnPreferredBuyersList(data)
  }, [buyersList, preferredBuyersList])

  useEffect(() => {
    setBuyersList((prevBuyers) => {
      const updatedBuyers = prevBuyers.map((buyer) => {
        if (preferredBuyersList.includes(buyer.user_id._id)) {
          return { ...buyer, isSelected: true, isPreferred: true }
        }
        return buyer
      })
      checkIfAllSelected(updatedBuyers)
      return updatedBuyers
    })
  }, [preferredBuyersList])

  useEffect(() => {
    if (buyersList.length > 0) {
      const allPreferredSelected = buyersList
        .filter((buyer) => preferredBuyersList.includes(buyer.user_id._id))
        .every((buyer) => buyer.isSelected)

      setIsAllPreferredSelected(allPreferredSelected)
    }
  }, [buyersList, preferredBuyersList])

  useEffect(() => {
    if (selectedBuyersList.length > 0) {
      setSelectBuyerError(false)
    }
  }, [selectedBuyersList])

  const handleInputChangeMain = (event) => {
    setSearchQueryMain(event.target.value)
  }

  const handleInputChangeSelected = (event) => {
    setSearchQuerySelected(event.target.value)
  }

  const togglePreferred = async (userId, isPreferred) => {
    try {
      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-auctions/create/preferred/user/${userId}`,
      )
      if (response.data.success) {
        setBuyersList((prevBuyers) => {
          const updatedBuyers = prevBuyers.map((buyer) => {
            if (buyer.user_id._id === userId) {
              if (isPreferred) {
                setPreferredBuyersList((prev) =>
                  prev.filter((val) => val !== userId),
                )
                return { ...buyer, isPreferred: false, isSelected: false }
              } else {
                setPreferredBuyersList((prev) => [...prev, userId])
                return { ...buyer, isPreferred: true }
              }
            }
            return buyer
          })

          const allPreferredBuyers = updatedBuyers.filter(
            (buyer) => buyer.isPreferred,
          )
          const allPreferredSelected = allPreferredBuyers.every(
            (buyer) => buyer.isSelected,
          )

          setIsAllPreferredSelected(allPreferredSelected)

          return updatedBuyers
        })

        CustomToast({
          message: response.data.message,
          type: 'success',
        })
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  const [selectBuyerError, setSelectBuyerError] = useState(false)

  const handleCheckboxChange = (user) => {
    setBuyersList((prevBuyers) => {
      const updatedBuyers = prevBuyers.map((buyer) => {
        if (buyer.user_id._id === user.user_id?._id) {
          return { ...buyer, isSelected: !buyer.isSelected }
        }
        return buyer
      })

      checkIfAllSelected(updatedBuyers)
      return updatedBuyers
    })
    setSelectBuyerError(false)
  }

  const handleRemoveFromSelected = (user) => {
    setBuyersList((prevBuyers) => {
      const updatedBuyers = prevBuyers.map((buyer) => {
        if (buyer.user_id._id === user?._id) {
          return { ...buyer, isSelected: false }
        }
        return buyer
      })

      checkIfAllSelected(updatedBuyers)
      return updatedBuyers
    })
  }

  const checkIfAllSelected = (buyers) => {
    const allSelected = buyers.every((buyer) => buyer.isSelected)
    const allPreferredSelected = buyers
      .filter((buyer) => preferredBuyersList.includes(buyer.user_id._id))
      .every((buyer) => buyer.isSelected)

    setIsAllBuyersSelected(allSelected)
    setIsAllPreferredSelected(allPreferredSelected)
  }

  useEffect(() => {
    const sortedBuyersList = buyersList.sort((a, b) => {
      if (
        (preferredBuyersList.includes(a.user_id._id) &&
          preferredBuyersList.includes(b.user_id._id)) ||
        (a.isPreferred && b.isPreferred)
      ) {
        return 0
      } else if (preferredBuyersList.includes(a.user_id._id) || a.isPreferred) {
        return -1
      } else if (preferredBuyersList.includes(b.user_id._id) || b.isPreferred) {
        return 1
      }
      return 0
    })

    let filteredBuyersList = sortedBuyersList.filter((buyer) => {
      return (
        buyer.user_id.first_name
          .toLowerCase()
          .includes(searchQueryMain.toLowerCase()) ||
        buyer.user_id.email
          .toLowerCase()
          .includes(searchQueryMain.toLowerCase())
      )
    })

    filteredBuyersList = filteredBuyersList.map((buyer) => {
      if (preferredBuyersList.includes(buyer.user_id?._id)) {
        return { ...buyer, isPreferred: true }
      }
      return { ...buyer }
    })

    setFilteredBuyersList(filteredBuyersList)
  }, [buyersList, preferredBuyersList, searchQueryMain])

  useEffect(() => {
    const sortedBuyersList = buyersList.sort((a, b) => {
      if (
        (preferredBuyersList.includes(a.user_id._id) &&
          preferredBuyersList.includes(b.user_id._id)) ||
        (a.isPreferred && b.isPreferred)
      ) {
        return 0
      } else if (preferredBuyersList.includes(a.user_id._id) || a.isPreferred) {
        return -1
      } else if (preferredBuyersList.includes(b.user_id._id) || b.isPreferred) {
        return 1
      }
      return 0
    })

    let filteredBuyersList = sortedBuyersList.filter((buyer) => {
      return (
        buyer.user_id.first_name
          .toLowerCase()
          .includes(searchQuerySelected.toLowerCase()) ||
        buyer.user_id.email
          .toLowerCase()
          .includes(searchQuerySelected.toLowerCase())
      )
    })

    filteredBuyersList = filteredBuyersList.map((buyer) => {
      if (preferredBuyersList.includes(buyer.user_id?._id)) {
        return { ...buyer, isPreferred: true }
      }
      return { ...buyer, isPreferred: false }
    })

    setSetSelectedBuyersList([
      ...filteredBuyersList.filter((buyer) => buyer.isSelected),
    ])
  }, [buyersList, preferredBuyersList, searchQuerySelected])

  const handleSelectAllPreferred = () => {
    setBuyersList((prevBuyers) => {
      const updatedBuyers = prevBuyers.map((buyer) => {
        if (
          buyer?.isPreferred ||
          preferredBuyersList.includes(buyer?.user_id?._id)
        ) {
          return { ...buyer, isSelected: true }
        }
        return { ...buyer }
      })

      setIsAllPreferredSelected(true)
      checkIfAllSelected(updatedBuyers)
      return updatedBuyers
    })
  }

  const handleSelectAll = () => {
    setBuyersList((prevBuyers) => {
      const areAllSelected = prevBuyers.every((buyer) => buyer.isSelected)

      const updatedBuyers = prevBuyers.map((buyer) => ({
        ...buyer,
        isSelected: !areAllSelected,
      }))

      setIsAllBuyersSelected(!areAllSelected)
      checkIfAllSelected(updatedBuyers)
      return updatedBuyers
    })
  }

  const handleRemoveAllPreferred = () => {
    setBuyersList((prevBuyers) => {
      const updatedBuyers = prevBuyers.map((buyer) => {
        if (
          buyer?.isPreferred ||
          preferredBuyersList.includes(buyer?.user_id?._id)
        ) {
          return { ...buyer, isSelected: false }
        }
        return { ...buyer }
      })

      setIsAllPreferredSelected(false)
      checkIfAllSelected(updatedBuyers)
      return updatedBuyers
    })
  }

  const handleRemoveAllSelected = () => {
    setBuyersList((prevBuyers) => {
      const updatedBuyers = prevBuyers.map((buyer) => ({
        ...buyer,
        isSelected: false,
      }))

      setIsAllBuyersSelected(false)
      setIsAllPreferredSelected(false)
      return updatedBuyers
    })
  }

  const [selectedStatus, setSelectedStatus] = useState('')
  const handleStatusChange = (value) => {
    if (selectedBuyersList.length === 0) {
      setSelectBuyerError(true)
    } else {
      setSelectedStatus(value)
      setStatusError(null)
      setSelectBuyerError(false)
    }
  }
  const [datetimeError, setDatetimeError] = useState(null)
  const [statusError, setStatusError] = useState(null)

  const [datetime, setDatetime] = useState(
    moment('YYYY-MM-DD h:mm A').format('YYYY-MM-DD h:mm A'),
  )
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [time, setTime] = useState(moment().format('h:mm A'))

  const handleDatetimeChange = (select, dateStr) => {
    select === 'Date' && setDate(moment(dateStr).format('YYYY-MM-DD'))
    select === 'Time' && setTime(moment(dateStr).format('h:mm A'))
  }

  useEffect(() => {
    if (date && time) {
      setDatetime(
        moment(date + ' ' + time, 'YYYY-MM-DD h:mm A').format(
          'YYYY-MM-DD h:mm A',
        ),
      )
      setDatetimeError(null)
    }
  }, [date, time])

  const [timezone, setTimezone] = useState(null)

  const handleTimezoneChange = (timezone) => {
    setTimezone(timezone)
  }

  useEffect(() => {
    const currentMoment = momenttz().tz(timezone?.value)
    const currentDate = currentMoment?.format('YYYY-MM-DD')
    const currentTime = currentMoment?.format('h:mm A')
    setDate(() => currentDate)
    setTime(() => currentTime)
  }, [timezone])

  useEffect(() => {
    if (buyersList.length > 0) {
      setBuyersList((prevBuyers) => {
        return prevBuyers.map((buyer) => {
          if (invitedBuyers.includes(buyer?.user_id?._id)) {
            return { ...buyer, isSelected: true }
          }
          return { ...buyer, isSelected: false }
        })
      })
    }
  }, [invitedBuyers, buyersList.length])

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    var date = new Date()
    var currentDatetime = moment(date).format('YYYY-MM-DD h:mm A')
    event.preventDefault()
    if (auctionDetails !== null) {
      try {
        setLoading(true)
        if (selectedBuyersList.length <= 0) {
          setSelectBuyerError('Please select buyer')
          setLoading(false)
          return
        } else {
          setSelectBuyerError(null)
        }
        if (!selectedStatus) {
          setStatusError('Please select a status')
          setLoading(false)
          return
        } else {
          setStatusError(null)
        }
        if (selectedStatus === 'scheduled') {
          if (!datetime) {
            setDatetimeError('Please select a date and time')
            setLoading(false)
            return
          } else {
            setDatetimeError(null)
          }
        }

        const userIds = selectedBuyersList.map((user) => user.user_id?._id)

        const data = {
          status: selectedStatus,
          launch_auction_date:
            selectedStatus === 'live' ? currentDatetime : datetime,
          launch_auction_timezone: timezone.value,
          invited_buyers: userIds,
          address: selectedSellerAddress,
        }

        const response = await axiosInterceptorInstance.put(
          `/vehicle/seller/vehicle-auctions/update/${id}`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        if (response.data.success) {
          navigate(`/${selectedStatus}`)
          setLoading(false)
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
        }
      } catch (error) {
        setLoading(false)
        handleRequestError(error)
      }
    } else {
      try {
        setLoading(true)
        if (selectedBuyersList.length <= 0) {
          setSelectBuyerError('Please select buyer')
          setLoading(false)
          return
        } else {
          setSelectBuyerError(null)
        }
        if (!selectedStatus) {
          setStatusError('Please select a status')
          setLoading(false)
          return
        } else {
          setStatusError(null)
        }
        if (selectedStatus === 'scheduled') {
          if (!datetime) {
            setDatetimeError('Please select a date and time')
            setLoading(false)
            return
          } else {
            setDatetimeError(null)
          }
        }
        const userIds = selectedBuyersList.map((user) => user.user_id?._id)

        const data = {
          status: selectedStatus,
          launch_auction_date:
            selectedStatus === 'live' ? currentDatetime : datetime,
          launch_auction_timezone: timezone.value,
          invited_buyers: userIds,
          address: selectedSellerAddress,
        }
        const response = await axiosInterceptorInstance.post(
          `/vehicle/seller/vehicle-auctions/create/${id}`,
          data,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        if (response.data.success) {
          navigate(`/${selectedStatus}`)
          setLoading(false)
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
        }
      } catch (error) {
        setLoading(false)
        handleRequestError(error)
      }
    }
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <CustomTitle backBtn />
      <CustomPageTitle vehicleDetails={vehicleDetails} />
      <div className='grid items-start justify-center w-full grid-cols-1 gap-10 large:grid-cols-3'>
        <div className='w-full'>
          <div className='flex flex-col items-start justify-start w-full gap-10 p-0 m-0'>
            <div className='relative overflow-hidden rounded-lg pt-[calc(9/16*100%)] large:pt-[205px] block w-full'>
              <img
                src={
                  vehicleDetails?.vehicle_image?.exterior_main ||
                  Placeholder_Image
                }
                alt='img'
                className='absolute top-0 left-0 object-cover w-full h-full'
                onError={(e) => vehicleBrokenImage(e)}
              />
            </div>
            <div className='hidden w-full border rounded-lg md:block border-gray/10'>
              <ul className='flex flex-col items-start justify-start w-full p-0 m-0'>
                <li className='flex flex-wrap items-center w-full py-4 max-xs:px-4 gap-y-3 even:bg-gray/5'>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='capitalize content'>
                      <p className='!font-medium'>vin</p>
                    </div>
                  </div>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='break-words whitespace-normal content'>
                      <p>
                        <CopyToClipboard>
                          {vehicleDetails?.vin_number}
                        </CopyToClipboard>
                      </p>
                    </div>
                  </div>
                </li>
                <li className='flex flex-wrap items-center w-full py-4 max-xs:px-4 gap-y-3 even:bg-gray/5'>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='capitalize content'>
                      <p className='!font-medium'>Trim</p>
                    </div>
                  </div>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='break-words whitespace-normal content'>
                      <p>{vehicleDetails?.trim}</p>
                    </div>
                  </div>
                </li>
                <li className='flex flex-wrap items-center w-full py-4 max-xs:px-4 gap-y-3 even:bg-gray/5'>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='capitalize content'>
                      <p className='!font-medium'>Engine</p>
                    </div>
                  </div>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='break-words whitespace-normal content'>
                      <p>{vehicleDetails?.engine}</p>
                    </div>
                  </div>
                </li>
                <li className='flex flex-wrap items-center w-full py-4 max-xs:px-4 gap-y-3 even:bg-gray/5'>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='capitalize content'>
                      <p className='!font-medium'>fuel type</p>
                    </div>
                  </div>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='break-words whitespace-normal content'>
                      <p>{vehicleDetails?.fuel_type}</p>
                    </div>
                  </div>
                </li>
                <li className='flex flex-wrap items-center w-full py-4 max-xs:px-4 gap-y-3 even:bg-gray/5'>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='capitalize content'>
                      <p className='!font-medium'>exterior color</p>
                    </div>
                  </div>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='break-words whitespace-normal color-snippet content'>
                      <p
                        style={{
                          background: vehicleDetails?.exterior_color,
                          border: '1px solid gray',
                        }}
                        aria-label={vehicleDetails?.exterior_color}
                      >
                        <CustomToolTip label={vehicleDetails?.exterior_color}>
                          <span></span>
                        </CustomToolTip>
                      </p>
                    </div>
                  </div>
                </li>
                <li className='flex flex-wrap items-center w-full py-4 max-xs:px-4 gap-y-3 even:bg-gray/5'>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='capitalize content'>
                      <p className='!font-medium'>interior color</p>
                    </div>
                  </div>
                  <div className='w-full xs:w-1/2 xs:px-5'>
                    <div className='break-words whitespace-normal color-snippet content'>
                      <p
                        style={{
                          background: vehicleDetails?.interior_color,
                          border: '1px solid gray',
                        }}
                        aria-label={vehicleDetails?.interior_color}
                      >
                        <CustomToolTip label={vehicleDetails?.interior_color}>
                          <span></span>
                        </CustomToolTip>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='w-full large:col-span-2'>
          <div className='w-full mb-4 lg:mb-8'>
            <div className='relative flex flex-col items-center justify-start w-full border rounded-lg border-gray/10'>
              <span className='w-6 h-6 md:w-8 md:h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-xs md:text-lg font-medium absolute -top-[12px] -left-[10px] md:top-[-10px] md:left-[-15px] z-10'>
                1
              </span>
              <div className='w-full p-3 border-b title title-gray md:p-5 border-gray/20'>
                <h3 className='flex flex-wrap items-center gap-4 mb-4 subHeading-5 lg:subHeading-3'>
                  <span className='block w-3 lg:w-10 h-[1px] bg-primary'></span>
                  Pickup location
                </h3>
                <div className='content'>
                  <p className='text-sm font-normal text-gray'>
                    Please select a pickup location and indicate the region
                    where you plan to sell your vehicle. Revvit will provide you
                    with a list of eligible buyers.
                  </p>
                </div>
              </div>
              <div className='flex flex-col items-start justify-start w-full m-0 overflow-x-hidden h-[200px] overflow-y-auto'>
                {sellerAddresses.map((address, index) => (
                  <label
                    htmlFor={`radio-${address._id}`}
                    className={`pickcheck items-center justify-between w-full pl-5 pr-5 py-2 flex flex-wrap relative border-b border-gray/10 last:border-b-0
                    ${
                      selectedSellerAddress?._id === address._id &&
                      'bg-primary/5 !text-primary'
                    }
                    ${
                      isBuyersLoading
                        ? 'cursor-wait opacity-55'
                        : 'cursor-pointer'
                    }`}
                    key={index}
                  >
                    <div className='flex flex-wrap items-center justify-start w-[calc(100%_-_44px)] m-0 p-0 order-2'>
                      <div className='flex flex-col items-start justify-start w-full'>
                        <div className={`title`}>
                          <h6 className='font-medium capitalize subHeading-6'>
                            {address.province}
                          </h6>
                        </div>
                        <div>
                          <p className='!text-xs'>
                            {address.street && `${address.street}, `}
                            {address.city && `${address.city}, `}
                            {address.postcode && `${address.postcode} `}
                            {address?.country
                              ? `, ${address.country}`
                              : `, Canada`}
                          </p>
                        </div>
                      </div>
                    </div>
                    <input
                      type='radio'
                      value={address}
                      checked={selectedSellerAddress?._id === address._id}
                      onChange={() => handleAddressChange(address)}
                      className='absolute order-1 opacity-0'
                      id={`radio-${address._id}`}
                      disabled={isBuyersLoading}
                    />
                    <div className='ico'>
                      <FaRegCircleCheck />
                    </div>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className='grid items-stretch justify-start w-full grid-cols-1 gap-10 xl:grid-cols-2'>
            <div className='w-full col-span-1'>
              <div className='relative flex flex-col items-center justify-start w-full border rounded-lg border-gray/10'>
                <span className='w-6 h-6 md:w-8 md:h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-xs md:text-lg font-medium absolute -top-[12px] -left-[10px] md:top-[-10px] md:left-[-15px] z-10'>
                  2
                </span>
                <div className='w-full p-3 title title-gray md:p-5'>
                  <h3 className='flex flex-wrap items-center gap-4 subHeading-5 lg:subHeading-3'>
                    <span className='block w-3 lg:w-10 h-[1px] bg-primary'></span>
                    All Buyers
                  </h3>
                </div>
                <div className='flex flex-wrap items-center justify-end w-full gap-3 p-0 px-3 m-0 mb-5 md:px-5'>
                  {filteredBuyersList?.some((user) => user.isPreferred) && (
                    <label className='relative flex flex-wrap items-center justify-start gap-1 p-0 m-0 cursor-pointer preferred group'>
                      <input
                        type='checkbox'
                        onChange={() => handleSelectAllPreferred()}
                        checked={isAllPreferredSelected}
                        className='absolute top-0 left-0 invisible opacity-0'
                      />
                      <FaRegCircleCheck className='ico text-gray/40 text-[18px] duration-300 delay-150 ease-linear group-hover:text-green' />
                      <div className='content'>
                        <p>All Preferred</p>
                      </div>
                    </label>
                  )}
                  {filteredBuyersList.some((user) => !user.isPreferred) && (
                    <label className='relative flex flex-wrap items-center justify-start gap-1 p-0 m-0 cursor-pointer preferred group'>
                      <input
                        type='checkbox'
                        onChange={() => handleSelectAll()}
                        checked={isAllBuyersSelected}
                        className='absolute top-0 left-0 invisible opacity-0'
                      />
                      <FaRegCircleCheck className='ico text-gray/40 text-[18px] duration-300 delay-150 ease-linear group-hover:text-green' />

                      <div className='content'>
                        <p>All Buyers</p>
                      </div>
                    </label>
                  )}
                </div>
                <div className='w-full p-2 overflow-hidden md:p-5 bg-gray/10 border-y border-gray/20'>
                  <input
                    type='text'
                    value={searchQueryMain}
                    onChange={handleInputChangeMain}
                    placeholder='Search by name'
                    className='w-full px-5 py-3 text-sm font-light border rounded-lg shadow-none outline-none border-gray/10 focus:border-primary'
                  />
                  {/* <button onClick={handleAddAllToFavorites}>
                    Add All to Favorites
                  </button> */}
                </div>
                <div className='park_buyer-list flex flex-col items-start justify-start w-full p-0 m-0 max-h-[200px] md:max-h-[345px] h-full overflow-x-hidden'>
                  {isBuyersLoading && (
                    <div className='flex flex-wrap items-center justify-between w-full gap-3 px-5 py-3 duration-300 ease-linear delay-150 border-b border-gray/10 last:border-0 hover:bg-primary/10 bg-primary/5'>
                      <div className='h-6 w-6 mx-auto animate-spin rounded-full border-2 border-solid border-primary border-t-transparent'></div>
                    </div>
                  )}
                  {!isBuyersLoading &&
                    filteredBuyersList.length < 1 &&
                    unPreferredBuyersList.length < 1 && (
                      <div className='flex flex-wrap items-center justify-between w-full gap-3 px-5 py-3 duration-300 ease-linear delay-150 border-b border-gray/10 last:border-0 hover:bg-primary/10'>
                        <p className='!text-sm'>
                          No buyers have been found in this region
                        </p>
                      </div>
                    )}

                  {filteredBuyersList.map((user, index) => (
                    <div
                      className='flex flex-wrap items-center justify-between w-full gap-3 px-5 py-2 duration-300 ease-linear delay-150 border-b border-gray/10 last:border-0 hover:bg-primary/10'
                      key={index}
                    >
                      <div className='flex flex-wrap items-center justify-start w-[calc(100%_-_44px)] m-0 p-0 gap-3'>
                        <div
                          onClick={() =>
                            togglePreferred(
                              user.user_id?._id,
                              user?.isPreferred,
                            )
                          }
                        >
                          {user.isPreferred ? (
                            <div className='flex flex-wrap items-center justify-center w-6 h-6 text-xs text-white border rounded-full cursor-pointer border-primary/40 bg-primary'>
                              <FaHeart />
                            </div>
                          ) : (
                            <div className='flex flex-wrap items-center justify-center w-6 h-6 text-xs bg-transparent border rounded-full cursor-pointer text-primary border-primary/40 hover:bg-primary hover:text-white'>
                              <FaRegHeart />
                            </div>
                          )}
                        </div>
                        <div className='flex flex-col items-start justify-start w-[calc(100%_-_36px)]'>
                          <div className='title title-gray'>
                            <h6 className='font-medium capitalize subHeading-6'>
                              {user?.user_id?.first_name}
                            </h6>
                          </div>
                          <div className='content'>
                            <p className='!text-xs'>{user?.user_id?.email}</p>
                          </div>
                        </div>
                      </div>

                      <div className='relative'>
                        <input
                          type='checkbox'
                          checked={selectedBuyersList.some(
                            (u) => u?.user_id?._id === user?.user_id?._id,
                          )}
                          onChange={() => handleCheckboxChange(user)}
                          className='block w-4 h-4 duration-300 ease-linear delay-150 bg-white border border-solid shadow-none outline-none cursor-pointer border-gray-400 appearance-none checked:bg-white checked:border-primary'
                        />

                        <svg
                          className={`absolute top-0 left-0 w-4 h-4 text-primary pointer-events-none ${
                            selectedBuyersList.some(
                              (u) => u?.user_id?._id === user?.user_id?._id,
                            )
                              ? 'opacity-100'
                              : 'opacity-0'
                          } transition-opacity duration-300 ease-linear`}
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M5 13l4 4L19 7'
                          ></path>
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {selectBuyerError && (
                <span className='text-sm text-danger'>
                  Kindly choose at least one buyer for invitation.
                </span>
              )}
            </div>
            <div className='w-full col-span-1'>
              <div className='relative flex flex-col items-center justify-start w-full border rounded-lg border-gray/10'>
                <span className='w-6 h-6 md:w-8 md:h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-xs md:text-lg font-medium absolute -top-[12px] -left-[10px] md:top-[-10px] md:left-[-15px] z-10'>
                  3
                </span>
                <div className='w-full p-3 title title-gray md:p-5'>
                  <h3 className='flex flex-wrap items-center gap-4 subHeading-5 lg:subHeading-3'>
                    <span className='block w-3 lg:w-10 h-[1px] bg-primary'></span>
                    Selected Buyers
                  </h3>
                </div>
                <div className='flex flex-wrap items-center justify-end w-full gap-3 p-0 px-3 m-0 mb-5 md:px-5'>
                  {selectedBuyersList.some((user) => user.isPreferred) && (
                    <label className='relative flex flex-wrap items-center justify-start gap-1 p-0 m-0 cursor-pointer remove group'>
                      <input
                        type='checkbox'
                        onChange={() => handleRemoveAllPreferred()}
                        className='absolute top-0 left-0 invisible opacity-0'
                      />
                      <IoRemoveCircleOutline className='text-xl duration-300 ease-linear delay-150 ico text-gray/40 group-hover:text-primary' />
                      <div className='content'>
                        <p>Remove Preferred</p>
                      </div>
                    </label>
                  )}
                  {selectedBuyersList.some((user) => !user.isPreferred) && (
                    <label className='relative flex flex-wrap items-center justify-start gap-1 p-0 m-0 cursor-pointer remove group'>
                      <input
                        type='checkbox'
                        onChange={() => handleRemoveAllSelected()}
                        className='absolute top-0 left-0 invisible opacity-0'
                      />
                      <IoRemoveCircleOutline className='text-xl duration-300 ease-linear delay-150 ico text-gray/40 group-hover:text-primary' />
                      <div className='content'>
                        <p>Remove All</p>
                      </div>
                    </label>
                  )}
                </div>
                <div className='w-full p-2 overflow-hidden md:p-5 bg-gray/10 border-y border-gray/20'>
                  <input
                    type='text'
                    value={searchQuerySelected}
                    onChange={handleInputChangeSelected}
                    placeholder='Search by name'
                    className='w-full px-5 py-3 text-sm font-light border rounded-lg shadow-none outline-none border-gray/10 focus:border-primary'
                  />
                </div>
                <div className='park_buyer-list flex flex-col items-start justify-start w-full p-0 m-0  max-h-[200px] md:max-h-[345px] h-full overflow-x-hidden'>
                  {selectedBuyersList.map((user, index) => (
                    <div
                      className='flex flex-wrap items-center justify-between w-full gap-3 px-5 py-2 duration-300 ease-linear delay-150 border-b border-gray/10 last:border-0 hover:bg-primary/10'
                      key={index}
                    >
                      <div className='flex flex-wrap items-center justify-start w-[calc(100%_-_44px)] m-0 p-0 gap-3'>
                        <button
                          className='flex flex-col items-center justify-center text-white duration-300 ease-linear delay-150 rounded-full w-7 h-7 bg-gray hover:bg-gray/10 hover:text-gray'
                          onClick={() => handleRemoveFromSelected(user.user_id)}
                        >
                          <FaTrash className='text-sm' />
                        </button>
                        <div className='flex flex-col items-start justify-start w-[calc(100%_-_40px)]'>
                          <div className='title title-gray'>
                            <h6 className='font-medium capitalize subHeading-6'>
                              {user?.user_id?.first_name}
                            </h6>
                          </div>
                          <div className='content'>
                            <p className='!text-xs'>{user?.user_id?.email}</p>
                          </div>
                        </div>
                      </div>
                      {user.isPreferred && (
                        <div className='flex flex-wrap items-center justify-center w-6 h-6 text-xs text-white border rounded-full border-primary/40 bg-primary'>
                          <FaHeart />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='flex flex-wrap items-center justify-center w-full p-0 m-0 xl:col-span-2'>
              <div className='relative w-full p-3 border rounded-lg md:p-5 border-gray/10'>
                <span className='w-6 h-6 md:w-8 md:h-8 bg-primary text-white rounded-full flex flex-wrap items-center justify-center m-0 p-0 text-xs md:text-lg font-medium absolute -top-[12px] -left-[10px] md:top-[-10px] md:left-[-15px] z-10'>
                  4
                </span>
                <form onSubmit={handleSubmit}>
                  <div className='flex flex-wrap items-center w-full gap-5 mb-8 sm:justify-start'>
                    <div className='flex flex-col items-start justify-start w-full p-0 pb-5 m-0 border-b border-gray/10'>
                      <div className='w-full mb-4 title title-gray last:mb-0'>
                        <h3 className='flex flex-wrap items-center gap-4 subHeading-5 lg:subHeading-3'>
                          <span className='block w-3 lg:w-10 h-[1px] bg-primary'></span>
                          Launch Vehicle / Schedule
                        </h3>
                      </div>
                      <div className='content'>
                        <p className='text-sm font-normal text-gray'>
                          Immediately launch this vehicle or schedule the time
                          you would like this vehicle to launch.
                        </p>
                      </div>
                    </div>
                    {statusList.map((option) => (
                      <div key={option.value} className='max-sm:flex-auto'>
                        <input
                          type='radio'
                          value={option.value}
                          checked={selectedStatus === option.value}
                          onChange={() => handleStatusChange(option.value)}
                          className='hidden'
                          id={`radio-${option.value}`}
                        />
                        <label
                          htmlFor={`radio-${option.value}`}
                          className={`custom-radio-new cursor-pointer  ${
                            selectedStatus === option.value
                              ? 'bg-primary text-white'
                              : 'bg-transparent text-primary'
                          }`}
                        >
                          <span>{option.label}</span>
                        </label>
                      </div>
                    ))}
                    {selectedStatus === 'scheduled' ? (
                      <div className='flex flex-wrap items-start justify-start w-full gap-5 pt-5 border-t border-gray/10'>
                        <div className='flex-auto w-1/3'>
                          <Select
                            value={timezone}
                            onChange={handleTimezoneChange}
                            options={timeZoneList}
                            placeholder='Select timezone'
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                background: '#FFFFFF',
                                color: '#E60A54',
                                borderRadius: '6px',
                                borderColor: 'rgba(59,59,59,0.4)',
                                boxShadow: state.isFocused ? null : null,
                                height: '46px',
                                minHeight: '32px',
                                fontSize: '14px',
                                fontWeight: '400',
                                '&:hover': {
                                  borderColor: state.isFocused
                                    ? '#E60A54'
                                    : '#E60A54',
                                },
                                '@media (max-width: 639px)': {
                                  height: '38px',
                                  fontSize: '14px',
                                },
                              }),
                              placeholder: (defaultStyles) => {
                                return {
                                  ...defaultStyles,
                                  fontSize: '14px',
                                  fontWeight: 400,
                                  color: '#000',
                                  opacity: '0.4',
                                }
                              },
                              option: (
                                styles,
                                { isDisabled, isFocused, isSelected },
                              ) => {
                                return {
                                  ...styles,
                                  borderBottom: '0.1px solid #bfbfbf',
                                  borderRadius: 0,
                                  cursor: 'pointer',
                                  color: isFocused ? '#FFF' : '#000',
                                  backgroundColor: isDisabled
                                    ? '#E60A54'
                                    : isSelected
                                      ? '#E60A54'
                                      : isFocused
                                        ? '#E60A54'
                                        : '#FFFFFF',
                                  '@media (max-width: 639px)': {
                                    fontSize: '13px',
                                  },
                                }
                              },
                              menuList: (provided) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0,
                              }),
                              singleValue: (styles) => ({
                                ...styles,
                                color: '#000',
                              }),
                            }}
                          />
                        </div>
                        <div className='flex-auto '>
                          <Flatpickr
                            className='form-control form-field custom-half-input'
                            options={{
                              dateFormat: 'Y-m-d',
                              minDate: date,
                            }}
                            value={date}
                            locale={true}
                            onChange={(e) => handleDatetimeChange('Date', e[0])}
                            placeholder='Select Launch date'
                          />
                          {datetimeError && (
                            <div className='text-danger'>{datetimeError}</div>
                          )}
                        </div>
                        <div className='flex-auto'>
                          <Flatpickr
                            className='form-control form-field custom-half-input'
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'h:i K',
                              minuteIncrement: 1,
                            }}
                            locale={true}
                            value={time}
                            onChange={(e) => handleDatetimeChange('Time', e[0])}
                            placeholder='Select Launch Time'
                          />
                          {datetimeError && (
                            <div className='text-danger'>{datetimeError}</div>
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {statusError && (
                    <span className='block -mt-4 text-sm text-danger'>
                      {statusError}
                    </span>
                  )}
                  <button
                    type='submit'
                    className={`btn btn-primary-ico max-md:flex-auto ml-auto disabled:opacity-50 ${
                      loading && 'hover:bg-white'
                    }`}
                    disabled={selectedBuyersList.length <= 0 || !selectedStatus}
                  >
                    {loading ? (
                      <div className='h-5 w-5 mx-auto animate-spin rounded-full border-2 border-solid border-[#E60A54] border-t-transparent' />
                    ) : (
                      <Fragment>
                        <IoArrowUp className='w-4 h-4' />
                        {selectedStatus === 'scheduled' ? 'Schedule' : 'Launch'}
                        Vehicle
                      </Fragment>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomLoadingSection>
  )
}

export default Launch
