import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import io from 'socket.io-client'

import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'
import CustomActiveBuyerSellerChat from '../../../../components/Custom/CustomActiveBuyerSellerChat'
import CustomPageTitle from '../../../../components/Custom/CustomPageTitle'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import userBrokenImages from '../../../../utils/brokenImage/userBrokenImages'
import vehicleBrokenImage from '../../../../utils/brokenImage/vehicleBrokenImage'

import PlaceholderCar from '../../../../assets/car/car-dummy.png'
import configManager from '../../../../config/configManager'
import CustomToast from '../../../../components/Custom/CustomToast'
import CustomVehicleDetails from '../../../../components/Custom/CustomBuyVehicleDetails'

const companyData = configManager()

const socket = io(companyData.API_BASE_URL)
const View = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [vehicleDetails, setvehicleDetails] = useState()
  const [vehicleCountry, setVehicleCountry] = useState('')
  const [offerList, setOfferList] = useState([])

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-details/details/pending/${id}`,
      )
      if (response.data.success) {
        setvehicleDetails(response.data.vehicleDetails)
        setVehicleCountry(
          response.data.vehicleDetails?.vehicle_auction?.address?.country ||
            'Canada',
        )
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }, [id])

  const fetchBuyerOfferList = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-auctions/list/offer/${id}`,
      )
      if (response.data.success) {
        if (response.data.offers.length > 0) {
          setOfferList(response.data.offers)
        }
      }
    } catch (error) {
      handleRequestError(error)
    }
  }, [id])

  const deleteExpiredOffers = useCallback(async () => {
    try {
      await axiosInterceptorInstance.delete(
        `/vehicle/seller/vehicle-auctions/delete/expired-offers/${id}`,
      )
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  }, [id])

  useEffect(() => {
    fetchData()
    fetchBuyerOfferList()
    deleteExpiredOffers()
    const handleVehicleBackToParkingLot = () => {
      fetchData()
    }

    const handleVehicleOfferAccept = () => {
      navigate(`/awarded/${id}`)
      CustomToast({
        message:
          'The seller has accepted an offer, so no further offers can be submitted.',
        type: 'success',
      })
    }

    socket.on('vehicle_back_to_parkingloat', handleVehicleBackToParkingLot)
    socket.on('vehicle_offer_accept', handleVehicleOfferAccept)

    return () => {
      socket.off('vehicle_back_to_parkingloat', handleVehicleBackToParkingLot)
      socket.off('vehicle_offer_accept', handleVehicleOfferAccept)
    }
  }, [fetchData, fetchBuyerOfferList, id, navigate, deleteExpiredOffers])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <CustomTitle backBtn />
      <CustomPageTitle vehicleDetails={vehicleDetails} />
      <div className='flex flex-wrap items-start large:items-stretch justify-start gap-y-10 m-0 p-0 w-full large:w-auto large:-mx-[15px]'>
        <div className='w-full large:w-6/12 large:px-[15px]'>
          <div className='relative w-full overflow-hidden block pt-[calc(10/16*100%)]'>
            <img
              src={
                vehicleDetails?.vehicle_image?.exterior_main || PlaceholderCar
              }
              alt='img'
              className='absolute top-0 left-0 object-cover w-full h-full rounded-lg'
              onError={(e) => vehicleBrokenImage(e)}
            />
          </div>
        </div>
        <div className='w-full large:w-6/12 large:px-[15px]'>
          <div className='block h-full'>
            <div className='e-con-boxed bg-white border-[1px] border-solid border-gray border-opacity-10 rounded-xl px-5 large:px-5 xl:px-7 mb-9 last:mb-0 h-full'>
              <div className='h-full py-5 e-con-innre large:py-5 xl:py-7'>
                <div className='flex justify-between w-full'>
                  <h6 className='text-xl font-medium text-gray'>Your Offers</h6>
                  <div className='flex flex-col items-end justify-center flex-auto text-sm font-medium text-primary'>
                    {offerList?.length || 0}&nbsp;
                    {offerList?.length > 1 ? 'Offers' : 'Offer'}
                  </div>
                </div>
                <div className='flex flex-col w-full gap-2 px-2 py-4 overflow-y-auto border rounded-lg max-h-60 bg-gray bg-opacity-10 border-gray border-opacity-10'>
                  {offerList?.length > 0 ? (
                    offerList?.map((offer, index) => {
                      return (
                        <div
                          key={index}
                          className='flex flex-wrap items-center justify-between w-full gap-2 px-3 py-2 text-sm font-medium bg-white border-b border-opacity-50 rounded-md text-gray bg-opacity-85 drop-shadow border-gray'
                        >
                          <div className='flex items-center gap-2'>
                            <div>
                              {offer?.buyer_details?.profile_picture ? (
                                <img
                                  src={offer?.buyer_details?.profile_picture}
                                  alt='vehicle-img'
                                  className='object-cover w-8 h-8 rounded-full'
                                  onError={(e) => userBrokenImages(e)}
                                />
                              ) : (
                                <div className='flex items-center justify-center w-8 h-8 rounded-full bg-light-gray text-primary'>
                                  {offer?.buyer_details?.first_name
                                    ?.substring(0, 2)
                                    ?.toUpperCase()}
                                </div>
                              )}
                            </div>
                            <p className='font-normal'>
                              {vehicleCountry === 'Canada'
                                ? 'CAD $'
                                : vehicleCountry === 'USA'
                                  ? 'USD $'
                                  : 'CAD $'}
                              {offer?.amount}
                            </p>
                          </div>
                          <p className='text-[10px] font-light text-gray text-opacity-90'>
                            {moment(offer?.created_at).format(
                              'MMMM Do YYYY, h:mm:ss a',
                            )}
                          </p>
                        </div>
                      )
                    })
                  ) : (
                    <div className='text-2xl font-medium text-center text-light-gray'>
                      No Offer
                    </div>
                  )}
                </div>
                <div className='w-full mt-auto bid_detail'>
                  <div className='listing_bid_int'>
                    <CustomActiveBuyerSellerChat
                      id={id}
                      vehicleDetails={vehicleDetails}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomVehicleDetails vehicleDetails={vehicleDetails} />
      </div>
    </CustomLoadingSection>
  )
}

export default View
