import React, { Fragment, useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { signOut } from '../../redux/user/userSlice'

import { removeToken, removeRole } from '../../utils/cookies/cookies'
import { handleRequestError } from '../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../utils/axios/axiosInterceptorInstance'
import { RemoveEditVehicleDetails } from '../../redux/vehicle/vehicleSlice'

import { Icons } from '../../components/Icons'

const {
  RiEdit2Line,
  IoCloseOutline,
  MdLogout,
  MdPayment,
  MdOutlineNotificationsActive,
  CgSpinner,
  GrTrash,
} = Icons

const ProfileSidebar = ({
  userDetails,
  profileSidebarOpen,
  setProfileSidebarOpen,
  profileref,
}) => {
  const { role } = useSelector((state) => state.user)

  const [isLoading, setIsLoading] = useState(false)
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const searchtrigger = useRef(null)
  const profilesidebar = useRef(null)

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!profileSidebarOpen || keyCode !== 27) return
      setProfileSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (profileref?.current?.contains(target)) return
      if (profilesidebar?.current?.contains(target)) return
      setProfileSidebarOpen(false)
    }

    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  const logouthandler = async () => {
    setIsLoading(true)
    try {
      await axiosInterceptorInstance.get('/auth/logout')
      dispatch(RemoveEditVehicleDetails())
      dispatch(signOut())
      removeRole()
      removeToken()
      navigate('/login')
      setIsLoading(false)
    } catch (error) {
      handleRequestError(error)
      setIsLoading(false)
    }
  }

  const [stripeCustomerId, setStripeCustomerId] = useState()

  const getCustomerId = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/payment/customer-id',
      )
      if (response.data.success) {
        setStripeCustomerId(response?.data?.user?.stripe_customer_id)
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  useEffect(() => {
    getCustomerId()
  }, [])

  const handleAccessPortal = async () => {
    if (stripeCustomerId) {
      try {
        const response = await axiosInterceptorInstance.post(
          '/payment/create-portal-session',
          { customerId: stripeCustomerId },
        )
        window.location.href = response.data.url
      } catch (error) {
        handleRequestError(error)
      }
    }
  }

  const softDeleteUser = async (userId) => {
    try {
      const response = await axiosInterceptorInstance.patch(
        `/auth/user/${userId}/soft-delete`,
      )

      if (response.data.success) {
        logouthandler()
      }
    } catch (error) {
      handleRequestError(error)
    }
  }

  return (
    <Fragment>
      <aside
        ref={profilesidebar}
        className={`fixed md:absolute border-r border-primary left-0 top-0 z-[99999] md:z-[9999] flex h-screen w-full md:w-[450px] flex-col overflow-y-hidden bg-[rgba(250,_249,_245,_1)] duration-300 ease-linear ${
          profileSidebarOpen
            ? 'translate-x-[0px] md:translate-x-[270px]'
            : '-translate-x-full'
        }`}
      >
        <div className='flex items-center justify-end gap-2 px-4 py-5'>
          <button
            ref={searchtrigger}
            onClick={() => setProfileSidebarOpen(false)}
          >
            <IoCloseOutline className='text-xl text-primary' />
          </button>
        </div>
        <div className='flex flex-col h-full py-5 duration-300 ease-linear no-scrollbar'>
          <div className='px-5 mb-10 title '>
            <h5 className='text-xl font-medium capitalize text-gray'>
              {userDetails?.first_name}
            </h5>
            <NavLink
              to={`/profile`}
              className='text-sm font-medium text-gray text-opacity-60'
            >
              View my profile
            </NavLink>
          </div>
          <div className='p-5 border-t border-b edit_profile border-gray border-opacity-30'>
            <button className='flex items-center gap-3 text-sm font-normal text-gray'>
              <RiEdit2Line className='text-xl' />
              <NavLink
                to={`/edit-profile`}
                className='text-sm font-medium text-gray text-opacity-60'
              >
                Edit my profile
              </NavLink>
            </button>
          </div>
          <div className='flex flex-col items-start justify-start w-full gap-6 p-0 px-5 m-0 my-10'>
            {role === 'buyer' && (
              <button
                className='flex items-center gap-3 text-sm font-normal cursor-pointer text-gray'
                onClick={handleAccessPortal}
              >
                <MdPayment className='text-xl' />
                <span className='hover:text-silver '>Manage Payments</span>
              </button>
            )}

            <NavLink
              to='/notifications'
              className={`text-sm font-normal  flex gap-3 items-center ${
                pathname === '/notifications' ? 'text-primary' : 'text-gray'
              }`}
            >
              <MdOutlineNotificationsActive className='text-xl' />
              <span className='hover:text-silver '>Notifications</span>
            </NavLink>

            <div className='flex gap-2'>
              <button
                onClick={() => !isLoading && logouthandler()}
                className='flex items-center gap-3 text-sm font-normal cursor-pointer text-gray'
              >
                {isLoading ? (
                  <CgSpinner className='w-4 h-4 animate-spin text-gray' />
                ) : (
                  <MdLogout className='text-xl' />
                )}
                <span className='hover:text-silver'>Logout</span>
              </button>
            </div>

            <button
              onClick={() => softDeleteUser(userDetails?._id)}
              className='flex items-center gap-3 text-sm font-normal cursor-pointer text-gray'
            >
              <GrTrash className='text-xl' />

              <span className='hover:text-silver'>Delete Account</span>
            </button>
          </div>
        </div>
      </aside>
    </Fragment>
  )
}

ProfileSidebar.propTypes = {
  userDetails: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    _id: PropTypes.string,
  }),
  profileSidebarOpen: PropTypes.bool.isRequired,
  setProfileSidebarOpen: PropTypes.func.isRequired,
  profileref: PropTypes.shape({
    current: PropTypes.any,
  }).isRequired,
}

export default ProfileSidebar
