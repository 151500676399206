import React, { useState } from 'react'
import * as Yup from 'yup'
import { NavLink, useNavigate } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'

import CustomInput from '../../../custom/CustomInput'

import axiosAuthInstance from '../../../utils/axios/axiosAuthInstance'

import LeftCard from '../../../views/Auth/LeftCard'
import DownloadApp from '../../../components/DownloadApp'

const schema = Yup.object().shape({
  first_name: Yup.string().required('Please enter your first name'),
  last_name: Yup.string().required('Please enter your last name'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email address'),
  password: Yup.string()
    .transform((value) => value.trim())
    .required('Please enter your password')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}$/,
      'The password must include at least one uppercase letter, one lowercase letter, one digit, one special character, and be a minimum of 8 characters long.',
    ),
  phone: Yup.string()
    .required('Please enter your phone number')
    .matches(
      /^(\d{10}|\(\d{3}\) \d{3}-\d{4})$/,
      'Phone number must be in the format (xxx) xxx-xxxx',
    ),
})

const Signup = () => {
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitDisable, setIsSubmitDisable] = useState(false)
  const [isError, setIsError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)

  const [isSignupFormFill, setSignFromFill] = useState(true)
  const [isEmailExistError, setIsEmailExistError] = useState(null)

  const [selectRoleError, setSelectRoleError] = useState(null)
  const [selectRole, setSelectRole] = useState(null)

  const errorRemovehandler = () => {
    setIsError(null)
    setSelectRoleError(null)
  }

  const handleSubmit = async (values, e) => {
    e.preventDefault()
    setIsSubmitting(true)

    if (formik.isValid && !isEmailExistError && selectRole) {
      setSignFromFill(false)
      try {
        const response = await axiosAuthInstance.post('/signup', {
          ...values,
          role: selectRole,
        })
        if (response.data.success) {
          const state = { email: response.data.email }
          navigate('/signup/success', { state })
          setSelectRoleError(null)
        }
      } catch (error) {
        if (error.response.data.message) {
          setIsError(error.response.data.message)
        } else {
          setIsError('Something went wrong. Please try again!')
        }
        setSelectRoleError(null)
      }
    }
    if (formik.isValid && !isSignupFormFill && !selectRole) {
      setSelectRoleError('Please select your role to continue.')
    }
    if (formik.isValid && !isEmailExistError) {
      setSignFromFill(false)
    }
    setIsSubmitting(false)
  }

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values, formikHelpers) => handleSubmit(values, formikHelpers),
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      auction_access_number: '',
    },
  })

  const handleUserRole = (e) => {
    setSelectRole(e.target.value)
    setSelectRoleError(null)
  }

  const userValidationHandler = async () => {
    setIsSubmitDisable(true)
    try {
      const response = await axiosAuthInstance.post('/isuser/existed', {
        email: formik.values.email,
      })
      if (response.data.success) {
        setIsEmailExistError(null)
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setIsEmailExistError(error.response.data.message)
      } else {
        setIsEmailExistError(
          'This email address is already associated with an account. Please use a different email or try logging in.',
        )
      }
    } finally {
      setIsSubmitDisable(false)
    }
  }

  return (
    <div className='login-root'>
      <div className='grid w-full h-auto grid-cols-1 p-0 m-0 overflow-y-auto lg:grid-cols-2 lg:h-full'>
        <LeftCard />

        <div className='login-card-root'>
          {isSignupFormFill ? (
            <div className='text-center title'>
              <h1>Sign up</h1>
              <p>
                Enter your details below to create an account and become our
                partner.
              </p>
            </div>
          ) : (
            <div className='text-center title'>
              <h1>
                Just <span className='text-primary'>One</span> More Step!
              </h1>
              <p>Please specify your identity?</p>
            </div>
          )}
          <FormikProvider value={formik}>
            <form
              onSubmit={(e) => {
                handleSubmit(formik.values, e)
              }}
              className='w-full large:max-w-[70%] border-t border-t-gray/10 pt-8'
            >
              {isSignupFormFill ? (
                <div className='form-field-wrapper'>
                  <Field
                    name='first_name'
                    placeholder='First Name*'
                    component={CustomInput}
                    className='form-field'
                    focusHandler={errorRemovehandler}
                  />
                  <Field
                    name='last_name'
                    placeholder='Last Name*'
                    component={CustomInput}
                    className='form-field'
                    focusHandler={errorRemovehandler}
                  />
                  <Field
                    type='email'
                    name='email'
                    placeholder='Email*'
                    component={CustomInput}
                    className='form-field'
                    focusHandler={errorRemovehandler}
                    userValidationCheck={true}
                    blurValidationHandler={userValidationHandler}
                  />
                  <Field
                    type={`${showPassword ? 'text' : 'password'}`}
                    name='password'
                    placeholder='Password*'
                    component={CustomInput}
                    className='form-field'
                    focusHandler={errorRemovehandler}
                    passwordshint
                    passwords
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                  />
                  <Field
                    type='text'
                    name='phone'
                    placeholder='Phone Number*'
                    component={CustomInput}
                    className='form-field'
                    focusHandler={errorRemovehandler}
                    userValidationCheck={true}
                    blurValidationHandler={userValidationHandler}
                  />
                  <Field
                    type='text'
                    name='auction_access_number'
                    placeholder='Auction Access Number'
                    component={CustomInput}
                    className='form-field'
                    required={false}
                    focusHandler={errorRemovehandler}
                  />
                </div>
              ) : (
                <div className='flex items-center justify-center w-full gap-2 mb-10'>
                  <label className='buyer'>
                    <input
                      type='radio'
                      name='role'
                      value='buyer'
                      onClick={handleUserRole}
                    />
                    <span>Buyer</span>
                  </label>
                  <span className='px-2 text-lg text-gray text-opacity-40 sm:px-5'>
                    OR
                  </span>
                  <label className='seller'>
                    <input
                      type='radio'
                      name='role'
                      value='seller'
                      onClick={handleUserRole}
                    />
                    <span>Seller</span>
                  </label>
                </div>
              )}

              {isError && (
                <div className='flex items-center justify-center mt-2 ml-1 text-lg font-normal text-danger'>
                  {isError}
                </div>
              )}

              {selectRoleError && (
                <div className='flex items-center justify-center mt-2 ml-1 text-lg font-normal text-danger'>
                  {selectRoleError}
                </div>
              )}

              {isEmailExistError && (
                <div className='flex items-center justify-center mt-2 ml-1 text-lg font-normal text-danger'>
                  {isEmailExistError}
                </div>
              )}

              {isSignupFormFill ? (
                <div className='my-5 btn-custom'>
                  <button
                    onClick={() => {
                      formik.handleSubmit(formik.values)
                    }}
                    disabled={isSubmitDisable}
                    type='submit'
                    className='btn btn-primary btn-full disabled:cursor-wait'
                  >
                    {isSubmitting ? (
                      <div className='w-5 h-5 m-auto border-2 border-solid rounded-full animate-spin border-t-transparent ' />
                    ) : (
                      'Sign up'
                    )}
                  </button>
                </div>
              ) : (
                <div className='btn-custom my-5 '>
                  <button
                    type='submit'
                    onClick={() => {
                      formik.handleSubmit(formik.values)
                    }}
                    className='btn btn-primary btn-full'
                  >
                    {isSubmitting ? (
                      <div className='w-5 h-5 m-auto border-2 border-solid rounded-full animate-spin border-t-transparent' />
                    ) : (
                      'Continue'
                    )}
                  </button>
                </div>
              )}
              {isSignupFormFill && (
                <div className='flex justify-center'>
                  <h4 className='text-base font-normal text-gray'>
                    Already have an account?&nbsp;
                    <NavLink to='/login'>
                      <span className='btn-primary-link'>Log in</span>
                    </NavLink>
                  </h4>
                </div>
              )}
            </form>
          </FormikProvider>
          <DownloadApp />
        </div>
      </div>
    </div>
  )
}

export default Signup
