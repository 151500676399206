import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import io from 'socket.io-client'
import moment from 'moment'

import CustomToast from '../../../../components/Custom/CustomToast'
import CustomTitle from '../../../../components/Custom/CustomTitle'
import CustomPageTitle from '../../../../components/Custom/CustomPageTitle'
import CustomVehicleDetails from '../../../../components/Custom/CustomBuyVehicleDetails'
import CustomLoadingSection from '../../../../components/Custom/CustomLoadingSection'
import CustomActiveBuyerSellerChat from '../../../../components/Custom/CustomActiveBuyerSellerChat'

import { handleRequestError } from '../../../../utils/axios/handleRequestError'
import axiosInterceptorInstance from '../../../../utils/axios/axiosInterceptorInstance'
import vehicleBrokenImage from '../../../../utils/brokenImage/vehicleBrokenImage'
import userBrokenImages from '../../../../utils/brokenImage/userBrokenImages'

import PlaceholderCar from '../../../../assets/car/car-dummy.png'

import configManager from '../../../../config/configManager'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { Pagination } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

const companyData = configManager()

const socket = io(companyData.API_BASE_URL)

const View = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [vehicleDetails, setVehicleDetails] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [vehicleCountry, setVehicleCountry] = useState('')
  const fetchCarDetails = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-details/details/active/${id}`,
      )
      if (response.data.success) {
        setVehicleDetails(response.data.vehicleDetails)
        setVehicleCountry(
          response.data.vehicleDetails?.vehicle_auction?.address?.country ||
            'Canada',
        )
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }, [id])

  const [offerList, setOfferList] = useState([])
  const [highestOffer, setHighestOffer] = useState()

  const fetchBuyerOfferList = useCallback(async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/buyer/vehicle-auctions/list/offer/${id}`,
      )
      if (response.data.success) {
        if (response.data.offers.length > 0) {
          setOfferList(response.data.offers)
          setHighestOffer(+response.data?.offers[0]?.highest_amount_offer)
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  }, [id])

  useEffect(() => {
    fetchCarDetails()
    fetchBuyerOfferList()
  }, [fetchBuyerOfferList, fetchCarDetails])

  const [offerAmount, setOfferAmount] = useState('')
  const [isOfferLoading, setIsOfferLoading] = useState(false)
  const [isOfferError, setIsOfferError] = useState('')
  const [isOfferExpireError, setIsOfferExpireError] = useState('')
  const [VehicleImages, setVehicleImages] = useState([])
  const [offerExpireAfter, setOfferExpireAfter] = useState(28)

  const submitOffer = async () => {
    if (offerAmount === null || offerAmount <= 0) {
      setIsOfferError('Please enter a valid amount')
    } else if (+highestOffer >= +offerAmount) {
      setIsOfferError(
        `You can only make an offer that is higher than the previous offer.`,
      )
    } else if (+offerExpireAfter < 1) {
      setIsOfferExpireError(
        'You are required to set the offer expiration time to a minimum of 1 days.',
      )
    } else {
      setIsOfferLoading(true)
      try {
        const response = await axiosInterceptorInstance.post(
          `/vehicle/buyer/vehicle-auctions/create/offer/${id}`,
          {
            amount: offerAmount,
            offerExpireAfter: moment().add(+offerExpireAfter, 'days'),
          },
        )
        if (response.data.success) {
          setIsOfferLoading(false)
          CustomToast({
            message: response.data.message,
            type: 'success',
          })

          socket.emit('Offer', id)

          fetchBuyerOfferList()
        }
      } catch (error) {
        setIsOfferLoading(false)
        handleRequestError(error)
      } finally {
        setOfferAmount('')
      }
    }
  }

  useEffect(() => {
    const handleVehicleExpired = () => {
      navigate(`/pending/${id}`)
      CustomToast({
        message:
          'The offer time has ended, and no further offers can be submitted.',
        type: 'success',
      })
    }

    const handleVehicleBackToParkingLot = () => {
      fetchCarDetails()
    }

    const handleVehicleOfferAccept = () => {
      navigate(`/awarded/${id}`)
      CustomToast({
        message:
          'The seller has accepted an offer, so no further offers can be submitted.',
        type: 'success',
      })
    }

    // Register socket event listeners
    socket.on('vehicle_expired', handleVehicleExpired)
    socket.on('vehicle_back_to_parkinglot', handleVehicleBackToParkingLot)
    socket.on('vehicle_offer_accept', handleVehicleOfferAccept)

    // Cleanup function to remove event listeners
    return () => {
      socket.off('vehicle_expired', handleVehicleExpired)
      socket.off('vehicle_back_to_parkinglot', handleVehicleBackToParkingLot)
      socket.off('vehicle_offer_accept', handleVehicleOfferAccept)
    }
  }, [id, navigate, fetchCarDetails])

  useEffect(() => {
    if (vehicleDetails?.vehicle_image) {
      const { ...vehicleImagesObject } = vehicleDetails.vehicle_image

      setVehicleImages(Object.values(vehicleImagesObject))
    }
  }, [vehicleDetails])

  const deleteExpiredOffers = async () => {
    try {
      await axiosInterceptorInstance.delete(
        `/vehicle/seller/vehicle-auctions/delete/expired-offers/${id}`,
      )
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  }

  useEffect(() => {
    deleteExpiredOffers()
  }, [id])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <CustomTitle backBtn />
      <CustomPageTitle vehicleDetails={vehicleDetails} timer />
      <div className='flex flex-wrap items-start  justify-start gap-y-10 m-0 p-0 w-full large:w-auto large:-mx-[15px]'>
        <div className='w-full large:w-6/12 large:px-[15px]'>
          <Swiper
            navigation={true}
            modules={[Navigation, Pagination]}
            pagination={true}
            className='vehicleImagesSwiper'
          >
            {VehicleImages.length > 0 ? (
              VehicleImages?.map((vehicleimg, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className='block relative overflow-hidden w-full pt-[calc(9/16*100%)] rounded-lg'
                  >
                    <img
                      src={vehicleimg || PlaceholderCar}
                      alt='img'
                      className='w-full h-full object-cover absolute top-0 left-0'
                      onError={(e) => vehicleBrokenImage(e)}
                    />
                  </SwiperSlide>
                )
              })
            ) : (
              <SwiperSlide className='block relative overflow-hidden w-full pt-[calc(9/16*100%)] rounded-lg'>
                <img
                  src={PlaceholderCar}
                  alt='img'
                  className='w-full h-full object-cover absolute top-0 left-0'
                  onError={(e) => vehicleBrokenImage(e)}
                />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
        <div className='w-full large:w-6/12 large:px-[15px]'>
          <div className='block h-full'>
            <div className='e-con-boxed bg-white border-[1px] border-solid border-gray border-opacity-10 rounded-xl px-5 large:px-5 xl:px-7 mb-9 last:mb-0 h-full'>
              <div className='h-full py-5 e-con-innre large:py-5 xl:py-7'>
                <div className='flex justify-between w-full'>
                  <h6 className='text-xl font-medium text-gray'>Your Offers</h6>
                  <div className='flex flex-col items-end justify-center flex-auto text-sm font-medium text-primary'>
                    {offerList?.length || 0}&nbsp;
                    {offerList?.length > 1 ? 'Offers' : 'Offer'}
                  </div>
                </div>
                <div className='flex flex-col w-full gap-2 px-2 py-4 overflow-y-auto border rounded-lg max-h-60 bg-gray bg-opacity-10 border-gray border-opacity-10'>
                  {offerList?.length > 0 ? (
                    offerList?.map((offer, index) => {
                      return (
                        <div
                          key={index}
                          className='flex flex-wrap items-center justify-between w-full gap-2 px-3 py-2 text-sm font-medium bg-white border-b border-opacity-50 rounded-md text-gray bg-opacity-85 drop-shadow border-gray'
                        >
                          <div className='flex items-center gap-2'>
                            <div>
                              {offer?.buyer_details?.profile_picture ? (
                                <img
                                  src={offer?.buyer_details?.profile_picture}
                                  alt='vehicle-img'
                                  className='object-cover w-8 h-8 rounded-full'
                                  onError={(e) => userBrokenImages(e)}
                                />
                              ) : (
                                <div className='flex items-center justify-center w-8 h-8 rounded-full bg-light-gray text-primary'>
                                  {offer?.buyer_details?.first_name
                                    ?.substring(0, 2)
                                    ?.toUpperCase()}
                                </div>
                              )}
                            </div>
                            <p className='font-normal'>
                              {vehicleCountry === 'Canada'
                                ? 'CAD $'
                                : vehicleCountry === 'USA'
                                  ? 'USD $'
                                  : 'CAD $'}
                              {offer?.amount}
                            </p>
                          </div>
                          <p className='text-[10px] font-light text-gray text-opacity-90'>
                            {moment(offer?.created_at).format(
                              'MMMM Do YYYY, h:mm:ss a',
                            )}
                          </p>
                        </div>
                      )
                    })
                  ) : (
                    <div className='text-2xl font-medium text-center text-light-gray'>
                      No Offer
                    </div>
                  )}
                </div>
                <div className='w-full my-auto bid_detail'>
                  <div className='listing_bid'>
                    {highestOffer ? (
                      <div>
                        <h6>Current Offer</h6>
                        <h4>
                          {vehicleCountry === 'Canada'
                            ? 'CAD $'
                            : vehicleCountry === 'USA'
                              ? 'USD $'
                              : ''}
                          {highestOffer?.toFixed(0)}
                        </h4>
                      </div>
                    ) : (
                      <p>You haven&apos;t submitted any offers yet.</p>
                    )}
                  </div>
                  <div className='flex flex-wrap items-start  w-full gap-5 p-0 m-0 mt-4'>
                    <div className='listing_bid_int flex-2'>
                      <p className='!m-0'>
                        Offer Amount (
                        {vehicleCountry === 'Canada' ? (
                          <strong>In CAD $</strong>
                        ) : vehicleCountry === 'USA' ? (
                          <strong>In USD $</strong>
                        ) : (
                          '$'
                        )}
                        ):
                      </p>
                      <div className='relative mt-1'>
                        <span className='dolor_sign'>$</span>
                        <input
                          type='text'
                          name='name'
                          placeholder='Offer Price'
                          step={10}
                          onFocus={() => {
                            setIsOfferError(null)
                            // setOfferAmount('')
                          }}
                          onChange={(e) => {
                            setIsOfferError(null)
                            let enteredValue = e.target.value
                            const filteredValue = enteredValue.replaceAll(
                              /[^0-9]/g,
                              '',
                            )

                            if (filteredValue.length > 9) return

                            if (!filteredValue && !offerAmount) {
                              return setOfferAmount('')
                            }
                            if (!filteredValue && offerAmount.length !== 1) {
                              return setOfferAmount(offerAmount)
                            }
                            setOfferAmount(filteredValue)
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' && !isOfferLoading) {
                              submitOffer()
                            }
                          }}
                          className='block bg-white border-[1px] border-gray focus:border-primary border-solid rounded-[8px] m-0 py-3 px-5 pl-8 w-full font-normal not-italic text-gray text-sm outline-none shadow-none'
                          value={offerAmount}
                        />
                      </div>
                      {isOfferError && (
                        <span className='text-xs text-primary'>
                          {isOfferError}
                        </span>
                      )}
                    </div>

                    <div className='flex flex-col gap-1 break-words flex-1'>
                      <p className='!m-0'>Offer Expire After Days:</p>
                      <input
                        type='number'
                        // min={28}
                        step={1}
                        placeholder='Offer valid for'
                        onChange={(e) => {
                          let enteredValue = e.target.value
                          if (enteredValue === '') {
                            setOfferExpireAfter('')
                            setIsOfferExpireError('')
                            return
                          }
                          const filteredValue = enteredValue.replaceAll(
                            /[^0-9]/g,
                            '',
                          )
                          if (filteredValue.length > 3) return

                          // if (!filteredValue) {
                          //   return setOfferExpireAfter(28)
                          // }

                          if (filteredValue >= 28) {
                            setIsOfferExpireError('')
                          }
                          // if (filteredValue < 28) {
                          //   setIsOfferExpireError(
                          //     'You are required to set the offer expiration time to a minimum of 28 days.',
                          //   )
                          // }
                          setOfferExpireAfter(+filteredValue)
                        }}
                        value={offerExpireAfter}
                        className='bg-white border-[1px] border-gray border-opacity-10 focus:border-primary border-solid rounded-[8px] m-0 py-3 px-2  font-normal not-italic text-gray text-sm outline-none shadow-none'
                      />
                      {isOfferExpireError && (
                        <span className='text-xs text-primary'>
                          {isOfferExpireError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='flex flex-wrap items-center justify-center w-full gap-5 p-0 m-0 mt-4'>
                    <button
                      disabled={isOfferLoading}
                      className='flex-auto btn btn-primary disabled:cursor-none'
                      onClick={submitOffer}
                    >
                      {isOfferLoading ? (
                        <div className='w-5 h-5 m-auto border-2 border-solid rounded-full animate-spin border-t-transparent ' />
                      ) : (
                        'Place Offer'
                      )}
                    </button>
                    <div>
                      <CustomActiveBuyerSellerChat
                        id={id}
                        vehicleDetails={vehicleDetails}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomVehicleDetails vehicleDetails={vehicleDetails} />
      </div>
    </CustomLoadingSection>
  )
}

export default View
