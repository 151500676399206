import React from 'react'
import moment from 'moment'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Loader from '../../Loader'
import CustomStopWatch from '../CustomStopWatch'

import vehicleBrokenImage from '../../../utils/brokenImage/vehicleBrokenImage'

import gif from '../../../assets/gif/car-jump.gif'
import placeholdercarimage from '../../../assets/car/car-dummy.png'

import { Icons } from '../../Icons'

const {
  PiSteeringWheel,
  BiSolidColorFill,
  LuFuel,
  LuUsers2,
  GiGearStickPattern,
  IoSpeedometerOutline,
  FiArrowUpRight,
} = Icons

const timezoneAbbreviations = {
  'Canada/Pacific': 'PT',
  'Canada/Mountain': 'MT',
  'Canada/Central': 'CT',
  'Canada/Eastern': 'ET',
  'Canada/Atlantic': 'AT',
  'Canada/Newfoundland': 'CN',
}

const getCurrencySymbol = (country) => {
  switch (country) {
    case 'Canada':
      return 'CAD $'
    case 'USA':
      return 'USD $'
    default:
      return 'CAD $'
  }
}

const CustomListView = ({ isLoading, data, CustomNoData }) => {
  const navigate = useNavigate()
  const location = useLocation()
  let { pathname } = location

  const DetailsConverter = (fieldData, optional) => {
    const data = fieldData && fieldData.toString().trim()

    if (data === 'Standard') {
      return 'Yes'
    }

    if (data === 'Optional') {
      return 'No'
    }

    if (data !== '' && data !== null) {
      return data
    }

    if (optional) {
      return optional
    }

    return '-'
  }

  const handleClick = (statusPath, itemId) => {
    navigate(`/${statusPath}/${itemId}`)
  }

  return (
    <div className='my-4'>
      {isLoading ? (
        <Loader />
      ) : !data || data.length <= 0 ? (
        pathname !== '/' ? (
          <div className='no-result flex flex-col items-center justify-center text-center h-[calc(100dvh_-_412px)]'>
            <div className='img'>
              <img
                src={gif}
                alt='vehicle-img'
                onError={(e) => vehicleBrokenImage(e)}
              />
            </div>
            <div className='title title-gray subH-70'>
              <h5 className='subHeading-5'>
                {CustomNoData || ' There are no vehicle listings available.'}
              </h5>
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-center justify-center mb-10 text-center no-result last:mb-0'>
            <div className='title title-gray'>
              <h5 className='subHeading-5'>
                There are no vehicle listings available.
              </h5>
            </div>
          </div>
        )
      ) : (
        <section className='mb-8 bg-white items_list rounded-tl-3xl last:mb-0'>
          <div className='relative flex flex-wrap justify-start w-full gap-10 p-0 m-0 xl:items-stretch'>
            <div className='w-full'>
              {data?.map((item) => {
                let statusPath = ''
                switch (item.status) {
                  case 'live':
                    statusPath = 'active'
                    break
                  case 'scheduled':
                    statusPath = 'upcoming'
                    break
                  case 'pending':
                    statusPath = 'pending'
                    break
                  case 'closed':
                    statusPath = 'awarded'
                    break
                  case 'sold':
                    statusPath = 'purchased'
                    break
                  default:
                    statusPath = 'vehicle'
                }
                return (
                  <div
                    onClick={() => handleClick(statusPath, item._id)}
                    key={item._id}
                    className='flex flex-wrap justify-start m-0 mb-10 last:mb-0 p-0 w-full border-[1px] border-solid border-gray border-opacity-10 rounded-xl relative overflow-hidden cursor-pointer'
                  >
                    <div className='w-full large:w-6/12 xl:w-4/12'>
                      <div className='img landscape lg:h-full'>
                        {item.status === 'closed' && (
                          <span className='bg-primary py-2 px-5 text-center text-xs leading-[1] font-normal text-white rounded-full absolute top-3 left-3 z-[1]'>
                            Awarded
                          </span>
                        )}
                        {item.status === 'sold' && (
                          <span className='bg-green py-2 px-5 text-center text-xs leading-[1] font-normal text-white rounded-full absolute top-3 left-3 z-[1]'>
                            Purchased
                          </span>
                        )}
                        {pathname === '/vehicle' &&
                          item.status === 'pending' && (
                            <span className='bg-primary py-2 px-5 text-center text-xs leading-[1] font-normal text-white rounded-full absolute top-3 left-3 z-[1]'>
                              Pending
                            </span>
                          )}

                        {item.status === 'live' && (
                          <span className='bg-primary py-2 px-5 text-center text-xs leading-[1] font-normal text-white rounded-full absolute top-3 left-3 z-[1]'>
                            <CustomStopWatch
                              end_date={item?.vehicle_auction?.end_auction_time}
                              timezone={
                                item?.vehicle_auction?.launch_auction_timezone
                              }
                            />
                            <br /> min left
                          </span>
                        )}
                        {item.status === 'scheduled' && (
                          <span className='bg-green py-2 px-5 text-center text-xs leading-[1] font-normal text-white rounded-full absolute top-3 left-3 z-[1] flex flex-wrap items-center'>
                            {moment(
                              item?.vehicle_auction?.launch_auction_date,
                              'YYYY-MM-DD HH:mm:ssZ',
                            ).format('YYYY MMM, D')}
                            <span className='mx-2 w-[1px] h-[17px] bg-white block'></span>
                            {moment(
                              item?.vehicle_auction?.launch_auction_date,
                              'YYYY-MM-DD THH:mm:ssZ',
                            ).format('h:mm A')}
                            &nbsp; (
                            {
                              timezoneAbbreviations[
                                item?.vehicle_auction?.launch_auction_timezone
                              ]
                            }
                            )
                          </span>
                        )}
                        <img
                          src={
                            DetailsConverter(
                              item?.vehicle_image?.exterior_main_thumbnail,
                            ) || placeholdercarimage
                          }
                          className='object-cover w-full h-full'
                          alt='vehicle-img'
                          onError={(e) => vehicleBrokenImage(e)}
                        />
                      </div>
                    </div>
                    <div className='w-full large:w-6/12 xl:w-8/12'>
                      <div className='relative flex flex-wrap items-center justify-center w-full px-6 py-8 m-0 max-sm:gap-y-5 gap-y-10 large:h-full'>
                        <div className='w-full xl:w-8/12 border-0 border-b-[1px] xl:border-b-0 xl:border-r-[1px] border-solid border-gray border-opacity-10 max-sm:pb-5 pb-[30px] xl:pb-0 xl:pr-[2vw] flex flex-wrap max-sm:gap-5 gap-8'>
                          <div className='flex flex-wrap items-center justify-start w-full'>
                            <div className='title title-gray last:mb-0 border-0 border-r border-gray/10 mr-3 pr-3'>
                              <h3 className='heading-3 !font-medium'>
                                {DetailsConverter(item?.year)}&nbsp;
                                {DetailsConverter(item?.make)}
                                &nbsp;
                                {DetailsConverter(item?.model)}
                              </h3>
                            </div>
                            <div className='content'>
                              <p>{DetailsConverter(item?.vin_number)}</p>
                            </div>
                          </div>
                          <ul className='list-unstyled'>
                            <li>
                              <IoSpeedometerOutline />
                              <div>
                                <p>
                                  Mileage
                                  <span>
                                    {DetailsConverter(item?.odometer)}
                                    &nbsp;
                                    {DetailsConverter(item?.mileage_unit)}
                                  </span>
                                </p>
                              </div>
                            </li>
                            <li>
                              <LuFuel />
                              <p>
                                Fuel Type
                                <span>{DetailsConverter(item?.fuel_type)}</span>
                              </p>
                            </li>
                            <li>
                              <GiGearStickPattern />
                              <p>
                                Transmission
                                <span>
                                  {DetailsConverter(item?.transmission_type)}
                                </span>
                              </p>
                            </li>
                          </ul>
                          <ul className='list-unstyled'>
                            <li>
                              <BiSolidColorFill />
                              <p>
                                Exterior Color
                                <span className='value'>
                                  <span aria-label={item?.exterior_color}>
                                    <span></span>
                                  </span>
                                </span>
                              </p>
                            </li>
                            <li>
                              <PiSteeringWheel />
                              <p>
                                Drive Type
                                <span>
                                  {DetailsConverter(item?.drivetrain)}
                                </span>
                              </p>
                            </li>
                            <li>
                              <LuUsers2 />
                              <p>
                                Passenger
                                <span>
                                  {DetailsConverter(item?.standard_seating)}
                                </span>
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className='w-full xl:w-4/12 xl:pl-[2vw]'>
                          <div className='flex flex-wrap items-center justify-between w-full p-0 m-0 xl:flex-col xl:justify-center'>
                            <div className='flex justify-center xl:w-full xl:mb-10 max-xl:order-2 xl:empty:mb-0'>
                              {item.status === 'live' && (
                                <div className='favourite'>
                                  <div className='inline-block text-xl sm:text-2xl text-center text-gray'>
                                    {DetailsConverter(
                                      item?.vehicle_auction?.offer_count,
                                    )}
                                    &nbsp;
                                    {item?.vehicle_auction?.offer_count > 1
                                      ? 'Offers'
                                      : 'Offer'}
                                  </div>
                                </div>
                              )}
                              {(item.status === 'sold' ||
                                item.status === 'closed') && (
                                <div className='favourite'>
                                  <div className='inline-block text-xl sm:text-2xl text-center text-gray'>
                                    {getCurrencySymbol(
                                      item?.vehicle_auction?.address?.country,
                                    )}
                                    {DetailsConverter(
                                      item?.vehicle_auction?.awarded_amount,
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='flex flex-wrap justify-between gap-5 p-0 m-0 max-xl:items-center xl:flex-col xl:w-full lg:gap-0 xl:h-full max-xl:order-1'>
                              <div className='btn-custom xl:w-full max-large:flex-auto'>
                                {item.status === 'live' && (
                                  <NavLink
                                    to={`/active/${item._id}`}
                                    className='btn btn-primary-ico btn-full'
                                  >
                                    Place Offer
                                    <FiArrowUpRight />
                                  </NavLink>
                                )}

                                {item.status === 'scheduled' && (
                                  <NavLink
                                    to={`/upcoming/${item._id}`}
                                    className='btn btn-primary-ico btn-full'
                                  >
                                    View Details
                                    <FiArrowUpRight />
                                  </NavLink>
                                )}

                                {item.status === 'pending' && (
                                  <NavLink
                                    to={`/pending/${item._id}`}
                                    className='btn btn-primary-ico btn-full'
                                  >
                                    View Details
                                    <FiArrowUpRight />
                                  </NavLink>
                                )}

                                {item.status === 'closed' && (
                                  <NavLink
                                    to={`/awarded/${item._id}`}
                                    className='btn btn-primary-ico btn-full'
                                  >
                                    View Details
                                    <FiArrowUpRight />
                                  </NavLink>
                                )}

                                {item.status === 'sold' && (
                                  <NavLink
                                    to={`/purchased/${item._id}`}
                                    className='btn btn-primary-ico btn-full'
                                  >
                                    View Details
                                    <FiArrowUpRight />
                                  </NavLink>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  )
}

CustomListView.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  CustomNoData: PropTypes.string,
}

export default CustomListView
