import React, { Fragment, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Tooltip } from 'react-tippy'
import PropTypes from 'prop-types'

import CustomTitle from '../../../components/Custom/CustomTitle'
import CustomButtonWithIcon from '../../../components/Custom/CustomButtonWithIcon'
import CustomLoadingSection from '../../../components/Custom/CustomLoadingSection'

import { handleRequestError } from '../../../utils/axios/handleRequestError'
import vehicleBrokenImage from '../../../utils/brokenImage/vehicleBrokenImage'
import axiosInterceptorInstance from '../../../utils/axios/axiosInterceptorInstance'

import gif from '../../../assets/gif/car-jump.gif'
import placeholderCar from '../../../assets/car/car-dummy.png'
import CopyToClipboard from '../../../utils/CopyVINClickbord/CopyVINClickbord'

import { Icons } from '../../../components/Icons'

const {
  FiUserCheck,
  AiOutlineDollar,
  RiErrorWarningLine,
  HiOutlineStatusOnline,
  IoSettingsOutline,
  MdOutlineAddPhotoAlternate,
  MdRemoveRedEye,
} = Icons

const getCurrencySymbol = (country) => {
  switch (country) {
    case 'Canada':
      return 'CAD $'
    case 'USA':
      return 'USD $'
    default:
      return 'CAD $'
  }
}

const Closed = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [closedCarList, setClosedCarList] = useState([])
  const [closedVehicleCount, setClosedVehicleCount] = useState()
  const [soldCarList, setSoldCarList] = useState([])

  const fetchCloseVehicleListData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/seller/vehicle-details/list/closed',
      )
      if (response.data.success) {
        setClosedVehicleCount(response.data.vehicleDetailsList?.length)
        setClosedCarList(response.data.vehicleDetailsList)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }
  const fetchSoldVehicleListData = async () => {
    try {
      const response = await axiosInterceptorInstance.get(
        '/vehicle/seller/vehicle-details/list/sold',
      )
      if (response.data.success) {
        setSoldCarList(response.data.vehicleDetailsList)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchCloseVehicleListData()
    fetchSoldVehicleListData()
  }, [])

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <ClosedVehicleList closedCarList={closedCarList} />
      <SoldVehicleList
        soldCarList={soldCarList}
        closedVehicleCount={closedVehicleCount}
      />
      {closedCarList?.length === 0 && soldCarList?.length === 0 && (
        <Fragment>
          <CustomTitle />
          <div className='no-result flex flex-col items-center justify-center text-center h-[calc(100dvh_-_412px)]'>
            <div className='img'>
              <img
                src={gif}
                alt='vehicle-img'
                onError={(e) => vehicleBrokenImage(e)}
              />
            </div>
            <div className='title title-gray subH-70'>
              <h5 className='subHeading-5'>
                There are no vehicle listings available.
              </h5>
            </div>
          </div>
        </Fragment>
      )}
    </CustomLoadingSection>
  )
}

Closed.propTypes = {
  closedCarList: PropTypes.array,
  soldCarList: PropTypes.array,
  closedVehicleCount: PropTypes.number,
}

export default Closed

const ClosedVehicleList = ({ closedCarList }) => {
  return (
    <Fragment>
      {closedCarList?.length > 0 && (
        <Fragment>
          <CustomTitle />
          <div className='grid w-full gap-5 py-2 mb-5 border-b lg:max-w-none lg:w-auto max-sm:grid-cols-2 max-lg:grid-cols-3 max-large:grid-cols-5 large:grid-cols-6 xl:grid-cols-6 border-gray border-opacity-10 place-items-center lg:py-5 last:mb-0 last:pb-0 last:border-b-0'>
            <div className='flex flex-col items-center justify-center flex-auto gap-2'>
              <MdOutlineAddPhotoAlternate className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Media</h5>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center flex-auto gap-2'>
              <RiErrorWarningLine className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Description</h5>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center flex-auto gap-2 max-large:hidden'>
              <FiUserCheck className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Buyer</h5>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
              <AiOutlineDollar className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Amount</h5>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center flex-auto gap-2 max-lg:hidden'>
              <HiOutlineStatusOnline className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Status</h5>
              </div>
            </div>
            <div className='flex-col items-center justify-center flex-auto hidden gap-2 sm:flex'>
              <IoSettingsOutline className='text-xl large:text-3xl text-primary' />
              <div className='title title-gray'>
                <h5 className='subHeading-5'>Action</h5>
              </div>
            </div>
          </div>
          {closedCarList?.map((item) => {
            return (
              <div
                className='relative flex flex-wrap items-center justify-center w-full border-b border-gray border-opacity-10 last:border-b-0'
                key={item._id}
              >
                <div className='grid w-full gap-5 py-3 lg:max-w-none max-sm:grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 large:grid-cols-6 xl:grid-cols-6 place-items-center lg:py-5'>
                  <div className='img block w-full  rounded-lg relative overflow-hidden pt-[calc(9/16*100%)]'>
                    {item?.vehicle_image?.exterior_main ? (
                      <img
                        src={item?.vehicle_image?.exterior_main}
                        alt='vehicle-img'
                        sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                        width='1920'
                        height='1080'
                        className='absolute top-0 left-0 object-cover w-full h-full'
                        onError={(e) => vehicleBrokenImage(e)}
                      />
                    ) : (
                      <img
                        src={placeholderCar}
                        className='absolute top-0 left-0 object-cover w-full h-full'
                        alt='vehicle-img'
                        sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                        width='1920'
                        height='1080'
                        onError={(e) => vehicleBrokenImage(e)}
                      />
                    )}
                  </div>
                  <div className='flex-auto'>
                    <div className='sm:text-center text-lg font-normal !text-gray'>
                      <div className='title title-gray'>
                        <h6 className='subHeading-6'>{item?.year}</h6>
                      </div>
                      <div className='title title-primary'>
                        <h6 className='!font-medium heading-6'>
                          {item?.make}&nbsp;
                          <br className='max-small:hidden' />
                          {item?.model}
                        </h6>
                      </div>
                    </div>
                    <div className='flex-auto mt-2'>
                      <div className='title ttitle-gray'>
                        <h6 className='subHeading-6'>
                          <CopyToClipboard>{item?.vin_number} </CopyToClipboard>
                        </h6>
                      </div>
                    </div>
                    <div className='mt-2 sm:hidden'>
                      <Tooltip
                        title='View Vehicle'
                        position='top'
                        trigger='mouseenter'
                      >
                        <CustomButtonWithIcon
                          icon={<MdRemoveRedEye />}
                          link={
                            item.status === 'closed'
                              ? `/closed/${item?._id}`
                              : `/sold/${item?._id}`
                          }
                          label='View'
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className='max-large:hidden'>
                    <div className='title title-gray lg:text-center'>
                      <h6 className='subHeading-5'>
                        <NavLink
                          to={`/buyer/profile/${item?.awarded_buyer_id?._id}`}
                          className='flex flex-col items-center justify-center gap-4 group'
                        >
                          <div className='flex flex-col'>
                            <span className='text-sm font-medium capitalize cursor-pointer text-gray group-hover:text-primary'>
                              {item?.awarded_buyer_id?.first_name}
                            </span>
                            <span className='text-xs font-normal normal-case text-gray text-opacity-60 group-hover:text-gray'>
                              {item?.awarded_buyer_id?.email}
                            </span>
                          </div>
                        </NavLink>
                      </h6>
                    </div>
                  </div>
                  <div className='max-lg:hidden'>
                    <div className='title title-primary'>
                      <h6 className='font-medium subHeading-6'>
                        {getCurrencySymbol(
                          item?.vehicle_auction?.address?.country,
                        )}
                        {item?.vehicle_auction?.awarded_amount}
                      </h6>
                    </div>
                  </div>
                  <div className='max-lg:hidden'>
                    <div className='title'>
                      <h6 className='font-medium capitalize subHeading-6'>
                        {item?.status}
                      </h6>
                    </div>
                  </div>
                  <div className='max-sm:hidden'>
                    <Tooltip
                      title='View Vehicle'
                      position='top'
                      trigger='mouseenter'
                    >
                      <CustomButtonWithIcon
                        icon={<MdRemoveRedEye />}
                        link={
                          item.status === 'closed'
                            ? `/closed/${item?._id}`
                            : `/sold/${item?._id}`
                        }
                        label='View'
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            )
          })}
        </Fragment>
      )}
    </Fragment>
  )
}

ClosedVehicleList.propTypes = {
  closedCarList: PropTypes.array.isRequired,
}

const SoldVehicleList = ({ soldCarList, closedVehicleCount }) => {
  return (
    <Fragment>
      {soldCarList?.length > 0 && (
        <div className={closedVehicleCount === 0 ? '' : 'mt-4'}>
          <CustomTitle customTitle='sold' />
          <div className='flex flex-col items-start justify-start w-full gap-10 p-0 m-0 mt-4'>
            {soldCarList.map((item) => {
              return (
                <div
                  className='relative flex flex-wrap w-full h-full border border-gray border-opacity-10 rounded-xl'
                  key={item._id}
                >
                  <div className='w-full lg:w-4/12'>
                    <div className='img block w-full h-full relative overflow-hidden pt-[calc(9/16*100%)]'>
                      <div className='bg-primary py-2 px-5 text-center text-xs leading-[1] font-normal text-white rounded-full absolute top-3 left-3 z-[1]'>
                        <div className='title'>
                          <h6 className='font-medium capitalize subHeading-6'>
                            {item?.status}
                          </h6>
                        </div>
                      </div>
                      {item?.vehicle_image?.exterior_main ? (
                        <img
                          src={item?.vehicle_image?.exterior_main}
                          alt='vehicle-img'
                          sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                          width='1920'
                          height='1080'
                          className='absolute top-0 left-0 object-cover w-full h-full rounded-tl-xl rounded-bl-xl'
                          onError={(e) => vehicleBrokenImage(e)}
                        />
                      ) : (
                        <img
                          src={placeholderCar}
                          className='absolute top-0 left-0 object-cover w-full h-full'
                          alt='vehicle-img'
                          sizes='(max-width: 960px) 480px, (max-width: 1920px) 1080px, 1920px'
                          width='1920'
                          height='1080'
                          onError={(e) => vehicleBrokenImage(e)}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full lg:w-8/12'>
                    <div className='flex flex-wrap items-center justify-center w-full gap-8 p-0 px-8 py-5 m-0 lg:h-full'>
                      <div className='flex flex-col flex-auto gap-5 lg:border-r border-gray/20'>
                        <div className='flex flex-col w-full gap-2 p-0 m-0'>
                          <div className='title title-gray'>
                            <h3 className='heading-3 !font-medium'>
                              {item?.year} {item?.make} {item?.model}
                            </h3>
                          </div>
                          <div>
                            <div className='title ttitle-gray text-40'>
                              <h5 className='subHeading-5'>
                                <CopyToClipboard>
                                  {item?.vin_number}
                                </CopyToClipboard>
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div>
                          <NavLink
                            to={`/buyer/profile/${item?.awarded_buyer_id?._id}`}
                            className='flex flex-col items-start justify-center'
                          >
                            <span className='text-sm font-normal capitalize cursor-pointer text-gray'>
                              {item?.awarded_buyer_id?.first_name}
                            </span>
                            <span className='text-sm font-normal normal-case text-gray text-opacity-60'>
                              {item?.awarded_buyer_id?.email}
                            </span>
                          </NavLink>
                        </div>
                      </div>
                      <div className='flex flex-col flex-auto gap-5 large:gap-10'>
                        <div>
                          <div className='title title-gray text-center'>
                            <h3 className='font-medium subHeading-2'>
                              {getCurrencySymbol(
                                item?.vehicle_auction?.address?.country,
                              )}
                              {item?.vehicle_auction?.awarded_amount}
                            </h3>
                          </div>
                        </div>

                        <div>
                          <Tooltip
                            title='View Vehicle'
                            position='top'
                            trigger='mouseenter'
                          >
                            <CustomButtonWithIcon
                              icon={<MdRemoveRedEye />}
                              link={
                                item.status === 'closed'
                                  ? `/closed/${item?._id}`
                                  : `/sold/${item?._id}`
                              }
                              label='View'
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </Fragment>
  )
}

SoldVehicleList.propTypes = {
  soldCarList: PropTypes.array,
  closedVehicleCount: PropTypes.number,
}
