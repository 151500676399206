import React from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import CustomSellerBuyerChat from '../../../components/Custom/CustomSellerBuyerChat'

import userBrokenImages from '../../../utils/brokenImage/userBrokenImages'
import vehicleBrokenImage from '../../../utils/brokenImage/vehicleBrokenImage'

import Placeholder_Image from '../../../assets/car/car-dummy.png'
import CopyToClipboard from '../../../utils/CopyVINClickbord/CopyVINClickbord'

const getCurrencySymbol = (country) => {
  switch (country) {
    case 'Canada':
      return 'CAD $'
    case 'USA':
      return 'USD $'
    default:
      return 'CAD $'
  }
}

const AwardedBuyer = ({
  id,
  vehicleDetails,
  sellerId,
  awardedBuyerDetails,
  vehicleAuctionDetails,
  hideChat = false,
  sold,
}) => {
  return (
    <div className='w-full'>
      <div className='overflow-hidden border border-gray/10 rounded-xl'>
        {!hideChat ? (
          <div className='relative flex flex-wrap items-center justify-between w-full gap-5 p-0 px-8 py-5 m-0 border bg-primary border-gray/5'>
            <div className='title title-white'>
              <h4 className='flex flex-wrap items-center gap-3 font-medium capitalize subHeading-4'>
                Buyer
              </h4>
            </div>
            <CustomSellerBuyerChat
              id={id}
              vehicleDetails={vehicleDetails}
              sellerId={sellerId}
              awardedBuyerDetails={awardedBuyerDetails}
            />
          </div>
        ) : null}
        <div className='flex flex-wrap items-start justify-between w-full px-5 py-5 md:flex-row md:items-center'>
          <BuyerCard awardedBuyerDetails={awardedBuyerDetails} />
        </div>
        <img
          src={
            vehicleDetails?.vehicle_image?.exterior_main || Placeholder_Image
          }
          alt='img'
          className='object-cover w-full'
          onError={(e) => vehicleBrokenImage(e)}
        />
        <div>
          <ul className='flex flex-col gap-2'>
            <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
              <div className='flex-1 title title-gray'>
                <h6 className='capitalize subHeading-5 ffont-medium'>VIN</h6>
              </div>
              <div className='content flex-2'>
                <p>
                  <CopyToClipboard>
                    {vehicleDetails?.vin_number}
                  </CopyToClipboard>
                </p>
              </div>
            </li>
            <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
              <div className='flex-1 title title-gray'>
                <h6 className='font-medium capitalize subHeading-5'>Vehicle</h6>
              </div>
              <div className='content flex-2'>
                <p>
                  {vehicleDetails?.year}&nbsp;{vehicleDetails?.make}&nbsp;
                  {vehicleDetails?.model}
                </p>
              </div>
            </li>
            <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
              <div className='flex-1 title title-gray'>
                <h6 className='font-medium capitalize subHeading-5'>
                  Awarded Offer
                </h6>
              </div>
              <div className='content flex-2'>
                <p className='inline-block !text-primary !text-lg !font-medium'>
                  {/* ${awardedBuyerDetails?.amount} */}

                  {getCurrencySymbol(vehicleAuctionDetails?.address?.country)}
                  {awardedBuyerDetails?.amount}
                </p>
              </div>
            </li>
            <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
              <div className='flex-1 title title-gray'>
                <h6 className='font-medium capitalize subHeading-5'>
                  Awarded Date
                </h6>
              </div>
              <div className='content flex-2'>
                <p>
                  {moment(vehicleAuctionDetails?.awarded_at).format(
                    'YYYY MMM, D h:mm A',
                  )}
                </p>
              </div>
            </li>
            {sold && (
              <li className='flex flex-wrap items-center gap-4 px-5 py-3 odd:bg-gray/10'>
                <div className='flex-1 title title-gray'>
                  <h6 className='font-medium capitalize subHeading-5'>
                    Payment Date
                  </h6>
                </div>
                <div className='content flex-2'>
                  <p>
                    {moment(vehicleAuctionDetails?.sold_at).format(
                      'YYYY MMM, D h:mm A',
                    )}
                  </p>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

AwardedBuyer.propTypes = {
  id: PropTypes.string.isRequired,
  vehicleDetails: PropTypes.shape({
    vehicle_image: PropTypes.shape({
      exterior_main: PropTypes.string,
    }),
    vin_number: PropTypes.string,
    year: PropTypes.number,
    make: PropTypes.string,
    model: PropTypes.string,
  }).isRequired,
  sellerId: PropTypes.string.isRequired,
  awardedBuyerDetails: PropTypes.shape({
    amount: PropTypes.number,
    buyer_id: PropTypes.shape({
      _id: PropTypes.string,
      profile_picture: PropTypes.string,
      first_name: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
  vehicleAuctionDetails: PropTypes.shape({
    awarded_at: PropTypes.string,
    sold_at: PropTypes.string,
    address: PropTypes.object,
  }).isRequired,
  title: PropTypes.string,
  hideChat: PropTypes.bool,
  sold: PropTypes.bool,
}

export default AwardedBuyer

const BuyerCard = ({ awardedBuyerDetails }) => {
  return (
    <div className='flex flex-col items-start justify-start w-full gap-5 p-0 m-0'>
      <NavLink to={`/buyer/profile/${awardedBuyerDetails?.buyer_id?._id}`}>
        <div className='flex items-center gap-3'>
          <div>
            {awardedBuyerDetails?.buyer_id?.profile_picture ? (
              <img
                src={awardedBuyerDetails?.buyer_id?.profile_picture}
                className='object-cover w-10 h-10 border rounded-full border-gray border-opacity-10'
                alt='img'
                onError={(e) => userBrokenImages(e)}
              />
            ) : (
              <div className='flex items-center justify-center w-10 h-10 rounded-full text-primary bg-light-gray'>
                {awardedBuyerDetails?.buyer_id?.first_name
                  ?.substring(0, 2)
                  ?.toUpperCase()}
              </div>
            )}
          </div>
          <div>
            <div className='title title-gray'>
              <h5 className='font-medium capitalize subHeading-5'>
                {awardedBuyerDetails?.buyer_id?.first_name}
              </h5>
            </div>
            <div className='content'>
              <p>{awardedBuyerDetails?.buyer_id?.email}</p>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  )
}

BuyerCard.propTypes = {
  awardedBuyerDetails: PropTypes.shape({
    amount: PropTypes.number,
    buyer_id: PropTypes.shape({
      _id: PropTypes.string,
      profile_picture: PropTypes.string,
      first_name: PropTypes.string,
      email: PropTypes.string,
    }),
  }).isRequired,
}
